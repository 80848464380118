<app-header></app-header>
 <br><br>  
			<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
                <div class="container text-center">
                        <h1 class="styleheader display-4"><strong>  Any brand, Any problem </strong></h1> 
                    </div>
			</div> 
    <br>
    <div class="container">
       
      <!-- <app-breadcrumb [index]=pagenumber></app-breadcrumb> -->
        
            <div class="row  h-100 justify-content-center align-items-center">                      
              <div class="col-md-7">             
                  <div class="section-title">
                      <h2>Computer Repair Services</h2>
                    </div>                  
              </div>
             
              <p class="lead header_p">
                  We know that nothing is more frustraiting than a electronic device not working properly  when you need it most.
                  perhaps is your computer need to be repair at the last minute because need for a work, and need it for today in a works time.
                  not matter what case getting a computer repair services is the key.
              </p>  
            </div>
        </div>
        <div class="container">
            <section class="d-flex bg-light" id="feature-cards">
                <div class="container d-flex justify-content-center">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-12 align-self-center">                           
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4  border-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-laptop" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Fix/Replace Screen</p>
                                                </div>
                                                    <p class="parrafo">We Repair OR Replace the broken Screen in your laptop by Cracked  Glass or Damage LCD or Other situation related with screen for all Brands all Models.
                                                         ACCER, HP, TOSHIBA, ASUS, DELL, Gateway, eMachines and More.</p>
                                                    
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-tasks" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Upgrade Computers </p>                                                    
                                                    <p class="parrafo">Sometimes the best option to improve a computer device is not purchase brand new, because we can help you to improve all hardware keeping insde your  budget, we can Upgrade the RAM memory, HDD, Microprocessor, Motherboard</p>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="row">
                                    <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa  fa-laptop-medical" aria-hidden="true"></i>                                                     
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Computer Tune-up</p>
                                                    <p class="parrafo">We clean, Restore and Optimize your computer with a high and deep  diagnoses optimizing all file system, hard driver and Windows Registry in your computer desktop or laptop. </p>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-trash" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Computer Virus Removal</p>
                                                    <p class="parrafo">We detect and perfomance remove viruses including Spyware, malware, rootkits and trojans in your laptop or desktop get back up running again, at the end of this services we perfomance a deep test to the Operating system to make sure is working properly without any computer virus infection</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                              
                                
                            </div>
                            
                       
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col mx-auto text-center">
                                            <i style="color:#29abe0;font-size:4em;" class="fa fa-battery-empty" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-center">
                                            <p class="subheading text-info">Replace/Fix laptop battery</p>
                                            <p class="parrafo">If your laptop battery has been dying too quickly or just not  charging at all, you should definitely give us call before    show  other  situation like  burn the motherboard </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col mx-auto text-center">
                                            <i style="color:#29abe0;font-size:4em;" class="fa  fa-microchip" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-center">
                                            <p class="subheading text-info">Black screen issues</p>
                                            <p class="parrafo">Black Screen of Death sometimes is result of Application issues this cause is not big deals, but if happens very often 
                                                the problem is a litte more serious hardware issues with overheating on the GPU component </p>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa  fa-cogs" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Operating system Installation</p>
                                                    <p class="parrafo">If you currently operating system in your device is super slow perfomance, freazing all the time, is time to do something and we can help you, but we need to see if the hardware support that operating system, if has a computer virus or other sintoms  </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                 <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa  fa-desktop" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Liquid damage</p>
                                                    <p class="parrafo">Liquids on electronic device are fatal!, is bad news but we can help to repair that liquid spill over your keyboard or motherboard not matter what if your spill water or wine, the accident happens!, is a hight percent your device is back again if you contact us right awawy after the accident, each second count in your device.</p>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    </div>
                   </div>
                </div>
                </div>                         
            </section>
        </div>    
        
            <div class="jumbotron jumbotron-fluid question">
                <div class="container">
                  <h3 class="text-center">Computer Repair Services Workflow </h3>
                  <p align="left">
                   We will run a repair procedure to fix any issues with your device like a Desktop,Laptop/Notebook including Mobile and Game devices.
                   We tipically fix within 3-5 days depending on the issues but you will be notified if it takes longer.
                   To carry out a reliableand quality computer repair we will describe below, the steps you need to take to use our computer repair services.                   
                  </p>
                    <div class="row">
                    <div class="col-sm-4 hello-image">
                        <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                        
                    </div>
                        <div class="col-sm-8">   
                        <h2 class="display-4">Step 1</h2>
                         <h4 class="subheading">Request Services.</h4>
                           <p class="lead">You need to contact us by phone call at (613) 627-6680 or toll free 1866-832-0692 or just fill out the form contact
                               to request the computer services. One of our staff will ask a few questions about your device issues.
                           </p>
                    </div>
                </div>
                
                    <div class="row">
                    <div class="col-sm-4 hello-image">
                        <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                        
                    </div>
                        <div class="col-sm-8">   
                        <h2 class="display-4">Step 2</h2>
                         <h4 class="subheading">Get your device.</h4>
                                    <p class="lead">
                                        You can drop off your device personally or one of the technichian who will pick it upby making an appointment.
                                        in any case a technichian will perform a quick evaluation and a light diagnostic about the possible issues with your device.
                                        <br/>
                                       
                                    </p>
                    </div>
                </div>
                       <div class="row">
                    <div class="col-sm-4 hello-image">
                        <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                        
                    </div>
                        <div class="col-sm-8">   
                        <h2 class="display-4">Step 3</h2>
                         <h4 class="subheading">Create services ticket.</h4>
                         <p class="lead">
                            Once we get your device, a Virtual ticket will be created including your contact information and device information plus the issues description.
                            A technichian will execute a free evaluation and diagnostic before we start working on fixing your device,
                            we will send a detailed quote including the price for the parts(in case you need to replace any hardware) by email,
                            , you must agree to sign an agreement in order to have your device fixed.
                            </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 hello-image">
                        <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                        
                    </div>
                        <div class="col-sm-8">   
                        <h2 class="display-4">Step 4</h2>
                         <h4 class="subheading">Pick up/Delivery.</h4>
                         <p class="lead">
                            After the devices's issues are resolved you will receive a notification that the services are done and ready
                            for pickup or we can delivery in you address by an appointment.
                            </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 hello-image">
                        <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                        
                    </div>
                        <div class="col-sm-8">   
                        <h2 class="display-4">Step 5</h2>
                         <h4 class="subheading">Resolve continued Issues.</h4>
                         <p class="lead">
                            After the services is done and you have the device we will call you to check the quality of our services,
                            but if you notice any new or unresolved issues in your device you will need to contact us as soon as possible.
                            We offer a 90 day warranty for our services, keep in mind the parts that might be replaced have their own warranty.
                            </p>
                    </div>
                </div>
                </div>
              </div>
        <!-- </div> -->
        <br>
  
        <app-getintouch  [pageindex]=pagenumber></app-getintouch>
        <br>       
      <app-subfooter></app-subfooter> 
    <br>
    <app-footer></app-footer>