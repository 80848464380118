<app-header></app-header> 
<br><br>
  
<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
          <div class="container text-center">
                <h1 class="styleheader display-4"><strong>  We Focus on Quality & Cost-Effective Solutions </strong></h1> 
          </div>
</div>

            <br/>
    <div class="container">
        <div class="row  h-100 justify-content-center align-items-center">                  
          <div class="col-md-7">                     
              <div class="section-title">
                <h2>Development Application Services</h2>
              </div>            
          </div>
          <p class="lead header_p">
              Driven by Technology , we like create custom software solutions for differents industries,
              we will love to help you in solve and develop innovations solutions for you company.  
          </p>  
        </div>
    </div>
    <br>
    <div class="container-fluid">         
        <div class="row">           
    <div class="background2"> 
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-4 how-img">
                <h4 class="headtitle">Desktop Software Development</h4>
                <img src="assets/img/desktopappdevelopment.png"  class="img-fluid" alt=""/>
            </div>
            <div class="col-md-6">
                            <!-- <h4 class="subheading">We have the neccesary tools for create and develop desktop application.</h4> -->
            <p class="text-muted">
              Sofisticated desktop solution create accomplish your aim, we help our customer in each step of the cycle software development
              to help you grow your business and reach your business goals.
            </p>
                                <ul class="fa-ul">
                                    <li><i class="fa-li fa fa-check-square"></i>Customization Software Development.</li>
                                    <li><i class="fa-li fa fa-check-square"></i>.NET Applications development.</li>
                                    <li><i class="fa-li fa fa-check-square"></i>Database Applications Integration.</li> 
                                    <li><i class="fa-li fa fa-check-square"></i>Payments API Integration.</li>                               
                                    <li><i class="fa-li fa fa-check-square"></i>WPF,UWP Application development.</li>
                                    <li><i class="fa-li fa fa-check-square"></i>Desktop applications modern.</li>
                                    <li><i class="fa-li fa fa-check-square"></i>Software Integration.</li>
                                    <li><i class="fa-li fa fa-check-square"></i>Multiplaform Enviroment Development.</li>                                     
                                  </ul>             
            </div>
        </div> 
      </div>
      </div>
      <br>
    <div class="row"> 
          <div class="background2"> 
              <div class="row h-100 justify-content-center align-items-center">
                  <div class="col-md-4 how-img ">
                      <h4 class="headtitle">Web Application Development</h4>
                      <img src="assets/img/webappdevelopment.png"  class="img-fluid" alt=""/>
                  </div>
                  <div class="col-md-6">                     
                   <!-- <h4 class="subheading">GetLance is a great place to find more clients, and to run and grow your own freelance business.</h4> -->
                  <p class="text-muted">
                   We provide professional Web sites that deliver transformational bottom line results. We use differents latest technologies and tools to perform the entire work process an effortless in only one. </p>                  
                                      <ul class="fa-ul">
                                            <li><i class="fa-li fa fa-check-square"></i>Web Apps Development.</li>
                                            <li><i class="fa-li fa fa-check-square"></i>Front End development Services.</li>
                                            <li><i class="fa-li fa fa-check-square"></i>Back End development Services.</li>
                                            <li><i class="fa-li fa fa-check-square"></i>Web Apps based with Database Integration.</li>                                            
                                            <li><i class="fa-li fa fa-check-square"></i>Ecomerce App Based on Web.</li>
                                            <li><i class="fa-li fa fa-check-square"></i>Modern Web applications CMS Wordpress.</li> 
                                            <li><i class="fa-li fa fa-check-square"></i>CMS Integration with other technologies.</li>                                                                                      
                                          </ul>
                                    </div>
              </div> 
          </div></div><br>
          <div class="row"> 
  <div class="background2">
      <div class="row h-100 justify-content-center align-items-center">
          <div class="col-md-4 how-img">
               <h4 class="headtitle">Mobile Applications Development</h4> 
              <img src="assets/img/mobileappdevelopment.png"  class="img-fluid" alt=""/>             
          </div>
          <div class="col-md-6">
                          <!-- <h4 class="subheading">GetLance is a great place to find more clients, and to run and grow your own freelance business.</h4> -->
          <p class="text-muted">
            We will create mobile app to solve the problem for either your company or clients or maybe for you only to recreate your idea. we offer focused mobile app design and development service.
          We work with 100% transparency during every step of the development process, working together to reducing your risk and ensuring a successful results.</p>
             
             
          </div>
      </div>
  </div> </div></div><br>
  
      <br/>
      <div class="section-block-grey">
        <div class="container">
            <div class="section-heading center-holder">
                <h2 >Our Core Technologies</h2>
                <div class="section-heading-line"></div>
                <p>
                    <br></p>
            </div>
            <div class="row mt-60">
                <div class="col-md-4 col-sm-12 col-lg-4">
                    <div class="serv-section-2">
                        <div class="serv-section-2-icon"> <i class="fas fa-users"></i> </div>
                        <div class="serv-section-desc">
                            <h5>Programming</h5>
                            <h5>Language</h5> </div>
                        <div class="section-heading-line-left"></div>
                        <ul class="fa-ul">                         
                        <li><i class="fa-li fa fa-check-square"></i>.Net Technology,Java,Python.</li>   
                        <li><i class="fa-li fa fa-check-square"></i>HTML,JavaScript,Typescript </li>    
                        <li><i class="fa-li fa fa-check-square"></i>Angular,React,Nodejs,PHP.</li> 
                        <li><i class="fa-li fa fa-check-square"></i>Android Studio, Xamarin Studio,IONIC.</li>                             
                  </ul> 
                 
                    </div>                    
                </div>
                <div class="col-md-4 col-sm-12 col-lg-4">
                    <div class="serv-section-2 serv-section-2-act">
                        <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="fas fa-database fa-3x"></i> </div>
                        <div class="serv-section-desc">
                            <h4>Database</h4>
                            <h5>Management Design</h5> </div>
                        <div class="section-heading-line-left"></div>
                        <ul class="fa-ul">
                          <li><i class="fa-li fa fa-check-square"></i>MYSQL Server.</li>
                          <li><i class="fa-li fa fa-check-square"></i>Microsoft SQL Server.</li>
                          <li><i class="fa-li fa fa-check-square"></i>Microsoft Data Access.</li>
                          <li><i class="fa-li fa fa-check-square"></i>Postgress SQL.</li>                        
                                                     
                        </ul>
                        <br>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 col-lg-4">
                  <div class="serv-section-2 serv-section-2-act">
                      <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="fas fa-laptop-code"></i> </div>
                      <div class="serv-section-desc">
                          <h4>Technology </h4>
                          <h5>Platforms</h5> </div>
                      <div class="section-heading-line-left"></div>
                      <ul class="fa-ul">
                        <li><i class="fa-li fa fa-check-square"></i>Microsoft Windows Server.</li>
                        <li><i class="fa-li fa fa-check-square"></i>Ubuntu,Linux Mint.</li>
                        <li><i class="fa-li fa fa-check-square"></i>Virtualization Platform,Dockering.</li> 
                        <li><i class="fa-li fa fa-check-square"></i>Cloud Platform AWS and Azure.</li>                                                    
                      </ul>
                      <br>
                  </div>
              </div>
             
          
       </div>
        </div>
    </div>
 
      <!-- <app-oursolutions></app-oursolutions>
      <br> -->
      <app-getintouch [pageindex]=pagenumber></app-getintouch>
      <br>

<div class="jumbotron jumbotron-fluid question">
  <div class="container">
    <h3 class="text-center">How we work for Development services?</h3>
    <p class="lead">
     If you have an idea want share with us?, we have help you with a guide about everything includng since concepts, design and product. 
    </p>
      <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 1</h2>
           <h4 class="subheading">Contact US.</h4>
             <p class="lead">Explore our development services  and determine what kind of application want to create according 
               with your necessity you can fill out the contact form or call us directly to discuss about you idea.
             </p>
      </div>
  </div>
  
      <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 2</h2>
           <h4 class="subheading">Recreate your Idea.</h4>
                      <p class="lead">We will collect all your requirements according your business necessity, we will execute a business analysis, UX research and the end will create a designer project.
                        </p>
      </div>
  </div>
         <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 3</h2>
           <h4 class="subheading">Legal Contract.</h4>
                      <p class="lead">Togueder we will elaborate a general agreegment about the price, terms and delivery time of the project</p>
      </div>
  </div>
  
         <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 4</h2>
           <h4 class="subheading">Road map project.</h4>
            <p class="lead">We will develop a project with all steps to follow, so in that way you will see STEP by STEP in what state we are in, and you will have a control over the process with regularly track progress.</p>
      </div>
  </div>
         <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 5</h2>
           <h4 class="subheading">Development process and lunch</h4>
            <p class="lead">Once everything in the project is on the table, we start building the application, transformer your idea and designed already in a application coding, testing and change what need to be modify follow the business requirements, for each requirements finish on the application with contact and  show you the result to continue on the next requirement.</p>
      </div>
  </div>
  </div>
</div>
<br>

      <br>
      
 <app-footer></app-footer>
