import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule ,HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { RepairComponent } from './pages/repair/repair.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { UserexperienceComponent } from './pages/userexperience/userexperience.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StatusComponent } from './pages/status/status.component';
import { TicketComponent } from './pages/ticket/ticket.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';

import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { SubfooterComponent } from './subfooter/subfooter/subfooter.component';
import { ContentsComponent } from './pages/contents/contents.component';
import { NetworksolutionsComponent } from './pages/networksolutions/networksolutions.component';
import { ItconsultingComponent } from './pages/itconsulting/itconsulting.component';
import { HomecontentComponent } from './pages/homecontent/homecontent.component';
import { HowweworkComponent } from './pages/howwework/howwework/howwework.component';
import { HeadermiddleComponent } from './headermiddle/headermiddle.component';
import { OursolutionsComponent } from './pages/oursolutions/oursolutions.component';
import { GetintouchComponent } from './pages/getintouch/getintouch.component';
import { BusinessintelligenceComponent } from './pages/businessintelligence/businessintelligence.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { PartnerComponent } from './pages/partner/partner.component';

import { SendemailService } from 'src/services/sendemail.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
 
 
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RepairComponent,
    MaintenanceComponent,
    DevelopmentComponent,
    UserexperienceComponent,
    ContactComponent,
    StatusComponent,
    TicketComponent,
    HomeComponent,
    ServicesComponent,
    PagenotfoundComponent,
    SubfooterComponent,
    ContentsComponent,
    NetworksolutionsComponent,
    ItconsultingComponent,
    HomecontentComponent,
    HowweworkComponent,  
    HeadermiddleComponent,
    OursolutionsComponent,
    GetintouchComponent,
    BusinessintelligenceComponent,
    IndustriesComponent,
    PartnerComponent,
    BreadcrumbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,   
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [SendemailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
