<app-header></app-header>    
<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
        <div class="container text-center">
                <h1 class="styleheader display-4"><strong>Challenge THINKING.LEAD Change</strong></h1> 
            </div>
      </div> 
<div class="container">  
        <div class="row justify-content-center">                      
    <div class="col-md-12">
        <div class="section-title">
           <h2>Industries</h2>
        </div>
     </div> 
    
        <div class="col-lg-12">  
                <p class="header_p lead">
                We invest significant time and effort in a wide variety of industries to help integrate, development and maintenance technologies,
                and Due to the strategic and competitive nature of our work, all our clients and projects are confidential.<br>
                We are passionate about taking on immense challenges that matter to our clients. We work with our clients hard keeping in a real direction, together to find the technology solutions.
                </p>  
        </div>
     </div>
</div>
<!--<div class="container" id="tourpackages-carousel">      
  <div class="row justify-content-center offset-1 ">
  <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4 '>-->
                 <!-- <div class="card hovercard">
                    <div class="cardheader">                         
                        <div class="info">
                                <div class="title">
                                        <h3>Health Care</h3>
                                </div>
                            </div>
                    </div>
                    <div class="avatar">                       
                        <span class="fa-stack fa-3x"> 
                        <i class="fa fa-circle fa-stack-3x icon-background2" ></i>
                        <i class="fa fa-angle-double-down fa-stack-2x"></i>                      
                        </span> 
                    </div>
                    <div class="card-body c1 flex-fill">
                        <div class="caption">                               
                        <ul class="fa-ul">
                                <li><i class="fa-li fa fa-check-square"></i>Dentals Offices.</li>
                                <li><i class="fa-li fa fa-check-square"></i>Medical Offices</li>
                                <li><i class="fa-li fa fa-check-square"></i>Medical Laboratory.</li>                                                      
                        </ul>
                  </div> -->
                 <!-- <span class="badge badge-pill badge-primary badge-lg">Dentals Offices</span>
                 <span class="badge badge-pill badge-secondary badge-lg">Medical Offices</span>
                 <span class="badge badge-pill badge-success badge-lg">Medical Laboratory</span>
                </div>                
                </div>
             </div>
             <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'>
                <div class="card hovercard">
                   <div class="cardheader">                         
                       <div class="info">
                               <div class="title">
                                       <h3>Small Offices</h3>
                               </div>
                           </div>
                   </div>
                   <div class="avatar">                       
                       <span class="fa-stack fa-3x"> 
                       <i class="fa fa-circle fa-stack-3x icon-background2" ></i>
                       <i class="fa fa-angle-double-down fa-stack-2x"></i>                      
                       </span> 
                   </div>
                   <div class="card-body c1  flex-fill">-->
                       <!-- <div class="caption">                               
                        <ul class="fa-ul">
                                <li><i class="fa-li fa fa-check-square"></i>Lawyers  Firms</li>
                                <li><i class="fa-li fa fa-check-square"></i>Faniancial Offices</li> 
                                <li><i class="fa-li fa fa-check-square"></i>Janitorial Offices</li>                              
                        </ul>
                 </div> -->
                 <!-- <span class="badge badge-pill badge-lg badge-primary">Lawyers  Firms</span>
                 <span class="badge badge-pill badge-lg badge-secondary">Faniancial Offices</span>
                 <span class="badge badge-pill badge-lg badge-success">Janitorial Offices</span>
                
               </div>                
               </div>
            </div>
            <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'>
                <div class="card hovercard">
                   <div class="cardheader">                         
                       <div class="info">
                               <div class="title">
                                       <h3>Others</h3>
                               </div>
                           </div>
                   </div>
                   <div class="avatar">                       
                       <span class="fa-stack fa-3x"> 
                       <i class="fa fa-circle fa-stack-3x icon-background2" ></i>
                       <i class="fa fa-angle-double-down fa-stack-2x"></i>                      
                       </span> 
                   </div>
                   <div class="card-body c1  flex-fill">
                       <div class="caption"> 
                                                       
                       <ul >
                               
                        <li><i class="fa-li fa fa-check-square"></i>Residential Sector</li>
                        <li><i class="fa-li fa fa-check-square"></i>Public services</li>
                        <li><i class="fa-li fa fa-check-square"></i>Garages </li>    
                        <li><i class="fa-li fa fa-check-square"></i>Restaurants</li>                        
                        <li><i class="fa-li fa fa-check-square"></i>Not Profit Organization</li> 
                        <li><i class="fa-li fa fa-check-square"></i>Embassy</li>  
                                                                   
                       </ul>
                 
                 </div> -->
                <!-- <span class="badge  badge-pill badge-lg badge-primary">Residential Sector</span>
 <span class="badge  badge-pill badge-lg badge-secondary">Public services</span>
<span class="badge  badge-pill badge-lg badge-success">Garages </span>
<span class="badge  badge-pill badge-lg badge-danger">Restaurants</span>
<span class="badge  badge-pill badge-lg badge-warning">Not Profit Organization</span>
<span class="badge  badge-pill badge-lg badge-info">Embassy</span>
<span class="badge badge-light">Light</span>
<span class="badge badge-dark">Dark</span> 
               </div>                
               </div>
            </div>-->
<!-- <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'>
  <div class="card mb-3" >
          <div class="offset-1">
                  <i class="fa fa-angle-double-down fa-3x"></i> 
                  </div>
          <div class="card-body">
                  <div class="caption">
                          <h3>Small Offices</h3>
                  <ul class="fa-ul">
                          <li><i class="fa-li fa fa-check-square"></i>Lawyers  Firms</li>
                          <li><i class="fa-li fa fa-check-square"></i>Faniancial Offices</li> 
                          <li><i class="fa-li fa fa-check-square"></i>Janitorial Offices</li>                              
                  </ul> 
            </div>
          </div>
        </div>  
</div> -->
<!-- <div class='col-sm-4 col-xs-6 col-md-4 col-lg-4'>
  <div class="card"  >
          <div class="offset-1">
                  <i class="fa fa-angle-double-down fa-3x"></i>
                  </div>
          <div class="card-body">
                  <div class="caption">
                          <h3>Others...</h3>
                  <ul class="fa-ul">
                          <li><i class="fa-li fa fa-check-square"></i>Residential Sector</li>
                          <li><i class="fa-li fa fa-check-square"></i>Government</li>
                          <li><i class="fa-li fa fa-check-square"></i>Garages </li>    
                          <li><i class="fa-li fa fa-check-square"></i>Restaurants</li> 
                          <li><i class="fa-li fa fa-check-square"></i>Grocery Stores</li> 
                          <li><i class="fa-li fa fa-check-square"></i>Not Profit Organization</li> 
                          <li><i class="fa-li fa fa-check-square"></i>Embassy</li>                                   
                        </ul> 

            </div>
          </div>
        </div>  
</div> -->
   

    
  <!--</div> -->
  <div class="container" > 
  <div class="row d-flex justify-content-center text-center align-items-center h-100">
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0" >
                <img src="assets/img/icons/icondentist.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title">Dental Offices</h6>
                  
                </div>
              </div>
      </div> 
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0" >
                <img src="assets/img/icons/iconlaboratorymedical.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title"> Medical Laboratory</h6>
                  
                </div>
              </div>
      </div>  
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0"  >
                <img src="assets/img/icons/iconmedicaloffice.png"  class="card-img-top img-fluid" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title">Medical Office</h6>
                  
                </div>
              </div>
      </div> 
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0" >
                <img src="assets/img/icons/icongarage.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title">Mechanic Garage</h6>
                  
                </div>
              </div>
      </div> 
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0" >
                <img src="assets/img/icons/iconrealstate.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title">Real Estate</h6>
                  
                </div>
              </div>
      </div> 
      <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
        <div class="card border-0"  >
                <img src="assets/img/icons/iconrestaurants.png"  class="card-img-top img-fluid" alt=""/> 
                <div class="card-body">
                  <h6 class="card-title">Restaurants</h6>
                  
                </div>
              </div>
      </div>    
  </div>

  <div class="row d-flex justify-content-center text-center align-items-center h-100">
       
        <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card " >
                        <img src="assets/img/icons/iconresident.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Residential</h6>
                          
                        </div>
                      </div>
              </div> 
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card border-0" >
                        <img src="assets/img/icons/iconshotel.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Hotels</h6>
                          
                        </div>
                      </div>
              </div>  
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card border-0"  >
                        <img src="assets/img/icons/iconslawyer.png"  class="card-img-top img-fluid" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Lawyer Office</h6>
                          
                        </div>
                      </div>
              </div> 
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card border-0" >
                        <img src="assets/img/icons/iconfinancial.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Financial Institutions</h6>
                          
                        </div>
                      </div>
              </div> 
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card border-0" >
                        <img src="assets/img/icons/iconnotprofit.png"  class="card-img-top img-fluid rounded-circle" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Organization Not profits</h6>
                          
                        </div>
                      </div>
              </div> 
              <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <div class="card border-0"  >
                        <img src="assets/img/icons/icongovernments.png"  class="card-img-top img-fluid" alt=""/> 
                        <div class="card-body">
                          <h6 class="card-title">Public Services</h6>
                          
                        </div>
                      </div>
              </div> 
         
    </div>

</div>
<!-- </div>End container -->
<br>
<app-getintouch [pageindex]=pagenumber></app-getintouch>
<app-footer></app-footer>