import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class BreadcrumService {

  constructor() { }

   getBreadCrumb(): Array<any> {
     const  bread=[
       {
         name:'Service',
         path:'service',
         component:'ServiceComponent',
         children:[
           {
             mame:'Repair',
             path:'Repair',
             component:'RepairComponent'
           },
           {
           name:'Maintenance',
           path:'Maintenance',
           component:'MaintenanceComponent'
           },
           {
            name:'Networksolutions',
            path:'Networksolutions',
            component:'NetworksolutionsComponent'
           },
           {
            name: 'userexperience',
            path: 'userexperience',
            component: 'UserexperienceComponent'
           },
           {
            name: 'Development', 
            path: 'Development',
            component: 'DevelopmentComponent'
           }
         ]
       }
     ];    
     return bread;
   }
}
