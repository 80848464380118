import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.css']
})
export class ContentsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  GoToRepairComponent() {
    console.log('Repair component');
    this.router.navigate(['/repair']);
  }

  loadMaintenanceComponent() {
    console.log('Maintenance component');
    this.router.navigate(['/maintenance']);
  }
  loadNetworkComponent() {
    this.router.navigate(['/networksolutions']);
  }
  loadDevelopmentComponent() {
    this.router.navigate(['/development']);
  }
  loadConsultingComponent() {
    this.router.navigate(['/consulting']);
  }
  loadExperienceDesignComponent() {
    this.router.navigate(['/userexperience']);
  }

}
