import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
// import { FormBuilder, FormArray, Validators } from "@angular/forms";

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Contact } from 'src/classes/contact';
import { SendemailService } from 'src/services/sendemail.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  caption_submit: any;
  response: any = '';
  // ContactForm:FormGroup;
  ContactModel = new Contact();
  submitted = false;
  error: {};
  selectedFrequency: any;
  @Input() pagenumber: number;
  isShowdeviceType: Boolean = false;
  IsnotSubmit: Boolean = false;
  private selectedNetwork: any;
  public isShowCompMaintenanceInfo: Boolean = false;
  public isShowCompNetworkInfo: Boolean = false;
  public isShowCompDevlopmentInfo: Boolean = false;
  public isShowUXserviceInfo: Boolean = false;
  public isShowSolutionsserviceInfo: Boolean = false;
  public isShowAffiliateserviceInfo: Boolean = false;
  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_fbbg87b',
        'template_c4yffos',
        e.target as HTMLFormElement,
        'fZTz5NxDB3pLm6hH3'
      )
      .then(
        (result: EmailJSResponseStatus) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  public DeviceTypes = [
    { id: 1, name: 'Personal Computer(Desktop)' },
    { id: 2, name: 'Laptop' },
    { id: 3, name: 'Servers' },
    { id: 4, name: 'Game Console' },
    { id: 5, name: 'Tablets' },
  ];
  public FrequenciesServices = [
    { id: 1, name: 'Every 3 Months' },
    { id: 2, name: 'Every 6 Months' },
    { id: 3, name: 'Every 9 Months' },
    { id: 4, name: 'Every 12 Months' },
    { id: 5, name: 'Just on Call' },
  ];
  public NetworkServices = [
    { id: 1, name: 'Network design' },
    { id: 2, name: 'Device Installation' },
    { id: 3, name: 'Troubleshooting Network' },
    { id: 4, name: 'Network Maintenance' },
    { id: 5, name: 'I don' + ' t  know' },
  ];
  public ApplicationsServices = [
    { id: 1, name: 'Web Application' },
    { id: 2, name: 'Desktop Applicationn' },
    { id: 3, name: 'Mobile Application' },
    { id: 5, name: 'I don' + ' t  know' },
  ];
  public ApplicationsTypeServices = [
    { id: 1, name: 'Health care' },
    { id: 2, name: 'Booking App' },
    { id: 3, name: 'Retails & Ecommerce App' },
    { id: 4, name: 'Restaurant App' },
    { id: 5, name: 'Finantial App' },
    { id: 6, name: 'Fleet management App' },
    { id: 7, name: 'I don' + ' t  know' },
  ];
  public UXTypeServices = [
    { id: 1, name: 'UX Design Only' },
    { id: 2, name: 'UX Design and Desktop App' },
    { id: 3, name: 'UX Design and Web App' },
    { id: 4, name: 'UX Design and Mobile App' },
  ];
  public Services = [
    { id: 1, name: 'Computer Repair' },
    { id: 2, name: 'Computer Maintenance' },
    { id: 3, name: 'Computer Network' },
    { id: 4, name: 'UX Designer' },
    { id: 5, name: 'Application Development' },
    { id: 6, name: 'Digital Marketing' },
    { id: 7, name: 'I don' + ' t  know' },
  ];
  registrationForm: UntypedFormGroup;

  //----------------------------Constructor------------------
  constructor(
    private route: ActivatedRoute,
    private sendServices: SendemailService,
    public fb: UntypedFormBuilder
  ) {
    this.IsnotSubmit = false;
    this.registrationForm = new UntypedFormGroup({
      fullname: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]*'),
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]),
      url: new UntypedFormControl('', Validators.required),
      subject: new UntypedFormControl('', Validators.required),
      totaldevice: new UntypedFormControl('', Validators.required),

      //  netService: new FormControl('',Validators.required)
    });
  }

  ngOnInit() {
    // this.ContactForm = new FormGroup({
    //   fullname : new FormControl(['',Validators.required,Validators.pattern('^[_A-z0-9]*((-|\s)*[_A-z0-9])*$')]),
    //   email: new FormControl(null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
    //   phoneNumber: new FormControl(['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]+$')]])
    //  });

    this.caption_submit = 'Submit Now';
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));

    if (this.pagenumber === 1) {
      this.isShowdeviceType = true;
    }
    if (this.pagenumber === 2) {
      this.isShowCompMaintenanceInfo = true;
    }
    if (this.pagenumber === 3) {
      this.isShowCompNetworkInfo = true;
    }
    if (this.pagenumber === 4) {
      this.isShowUXserviceInfo = true;
    }
    if (this.pagenumber === 5) {
      this.isShowCompDevlopmentInfo = true;
    }
    if (this.pagenumber === 9) {
      this.isShowSolutionsserviceInfo = true;
    }
    if (this.pagenumber === 8) {
      this.isShowAffiliateserviceInfo = true;
    }

    $(document).ready(() => {
      const $now = moment();
      $('#timepicker').bootstrapMaterialDatePicker({
        format: 'HH:mm',
        shortTime: false,
        date: false,
        time: true,
        monthPicker: false,
        year: false,
        switchOnClick: true,
      });
      $('#datepicker').bootstrapMaterialDatePicker({
        format: 'dddd DD MMMM YYYY',
        shortTime: false,
        date: true,
        time: false,
        monthPicker: false,
        year: false,
        showOn: 'button',
        buttonImageOnly: true,
        autoclose: true,
        switchOnClick: true,
      });
    });
  }

  onSubmit(f: NgForm) {
    this.submitted = true;
    // console.log(this.ContactModel);
    //setTimeout(function(){ this.caption_submit="Form has been Submiting, Please wait..."},5000);

    if (this.registrationForm.valid) {
      // this.getSentServices(this.ContactModel, f)
      console.log('probando...');
    }
  }

  get email(): any {
    return this.registrationForm.get('email');
  }
  get fullname(): any {
    return this.registrationForm.get('fullname');
  }

  get phone(): any {
    return this.registrationForm.get('phone');
  }
  get subject(): any {
    return this.registrationForm.get('subject');
  }

  get netService(): any {
    return this.registrationForm.get('netService');
  }

  get totaldevice(): any {
    return this.registrationForm.get('totaldevice');
  }
  getSentServices(body: Contact, f: NgForm) {
    console.log(body);
    this.sendServices.onSendResponseEmail(body).subscribe(
      (res) => {
        //setTimeout(function(){ this.caption_submit="The form was received sucessfull!!."},69000);
        this.IsnotSubmit = true;
        this.response = res;
        console.log(this.response);
        this.caption_submit = 'Submit Now';
      },
      (err) => {
        console.log(err);
      }
    );
  }
  resetForm(f: NgForm) {
    f.reset();
  }

  showHelp() {
    alert('aqui');
  }

  selectNetworkChange(event: any) {
    this.selectedNetwork = event.target.value;
    if (this.selectedNetwork > 0) {
      const s = this.NetworkServices[this.selectedNetwork - 1].name;
      console.log(s);
    }
  }
}
