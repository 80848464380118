import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-userexperience',
  templateUrl: './userexperience.component.html',
  styleUrls: ['./userexperience.component.css']
})
export class UserexperienceComponent implements OnInit {
  @Input() pagenumber: number;


  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
      console.log(this.pagenumber);

  }

}
