import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// import * as $ from 'jquery';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})

export class ServicesComponent implements OnInit {
  @Input() pagenumber: number;
  constructor(private route: ActivatedRoute) {
    console.log('services del menu');

 }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
  //   $(document).ready(() => {
  //           console.log('Jquery act ');
  //  });
  }}
