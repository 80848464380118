<!-- <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="text-center">How we work?</h3>
        <p>
         do you have an idea wnat share with us?, we have help you with a guide about everything includng since concepts, design and product. 
        </p>
        <ul class="timeline">
          <li>
            <div class="timeline-image">
              <img class="img-circle img-responsive "   alt=""> 
              <i class="fa fa-envelope-o fa-5x circle-icon"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>1</h4>
                <h4 class="subheading">Contact us</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  Explore our services what you need according with your necessety, we will be contact you following with a solutions.
                </p>
              </div>
            </div>
            <div class="line"></div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-image">
              <img class="img-circle img-responsive"   alt="">
              <i class="fa fa-pencil fa-5x circle-icon"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>2</h4>
                <h4 class="subheading">Legal Contracts</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                    Toguether we will elaborate general agreement about the price, terms and delivery time of the project.
                </p>
              </div>
            </div>         
            <div class="line"></div>
          </li>
          <li>
            <div class="timeline-image">
              <img class="img-circle img-responsive"   alt="">
              <i class="fa fa-handshake-o fa-5x circle-icon"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>3</h4>
                <h4 class="subheading">Recreate you idea</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                 We will collect all you requirements according with your aim, we will execute a business analysis, UX reserach and at the end ww will create Designer project.
                </p>
              </div>
            </div>         
            <div class="line"></div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-image">
              <img class="img-circle img-responsive"   alt="">
              <i class="fa fa-map-o fa-5x circle-icon"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>4</h4>
                <h4 class="subheading">Road Map project</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                    We will develop a project with all steps to follow, so in that way you will see step by step in what state we in, and you will have a control over the process with a regaulrly track progress
                </p>
              </div>
            </div>
            <div class="line"></div>
          </li>
          <li>
            <div class="timeline-image">
              <img class="img-circle img-responsive"   alt="">
              <i class="fa fa-dropbox fa-5x circle-icon"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>5</h4>
                <h4 class="subheading">Development process and lunch</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  Once everything in the project is clear for you and for us in your project, we start building the application, transfor your idea in software according with your requirements with a happy end launc
                  after that finish that process we desired keep in touch maybe for extra features or update in your software.


                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div> -->

  <div class="container">
    <h3 class="text-center">How we work?</h3>
        <p>
         do you have an idea want share with us?, we have help you with a guide about everything includng since concepts, design and product. 
        </p>
    <div class="row">
        <div class="col-md-12">
            <div class="main-timeline11">
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="year">Step 1</span>
                        <div class="inner-content">
                            <h3 class="title">Contact us</h3>
                            <p class="description">
                              Explore our services what you need according with your necessety, we will be contact you following with a solutions.
                            </p>
                        </div>
                      </div>
                </div>
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="year">Step 2</span>
                        <div class="inner-content">
                            <h3 class="title">Legal Contracts</h3>
                            <p class="description">
                              Toguether we will elaborate general agreement about the price, terms and delivery time of the project.
                            </p>
                        </div>
                      </div>
                </div>
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="year">Step 3</span>
                        <div class="inner-content">
                            <h3 class="title">Recreate you idea</h3>
                            <p class="description">
                              We will collect all you requirements according with your aim, we will execute a business analysis, UX reserach and at the end ww will create Designer project.
                            </p>
                        </div>
                      </div>
                </div>
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="year">Step 4</span>
                        <div class="inner-content">
                            <h3 class="title">Road Map project</h3>
                            <p class="description">
                              We will develop a project with all steps to follow, so in that way you will see step by step in what state we in, and you will have a control over the process with a regaulrly track progress
                            </p>
                        </div>
                      </div>
                </div>
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="year">Step 5</span>
                        <div class="inner-content">
                            <h3 class="title">Development process and lunch</h3>
                            <p class="description">
                              Once everything in the project is clear for you and for us in your project, we start building the application, transfor your idea in software according with your requirements with a happy end launc
                              after that finish that process we desired keep in touch maybe for extra features or update in your software.
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>