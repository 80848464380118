import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  @Input() pagenumber: number;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
    console.log(this.route.snapshot.queryParamMap.get('page'));
  }


}
