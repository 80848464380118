import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
 

@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.css']
})
export class RepairComponent implements OnInit {
  @Input() pagenumber: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
    console.log(this.route.snapshot.queryParamMap.get('page'));
//     $(document).ready(() => {
//       console.log(' Jquery act ');
// });
  }

}
