<app-header></app-header>
<!-- <div class="container"> -->
  <div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
    <div class="container text-center">
            <h1 class="styleheader display-4"><strong>  Delivery innovation user experience to your audience </strong></h1> 
        </div>
  </div> 
  
    <br/> 
<div class="container">
        <div class="row  h-100 justify-content-center align-items-center">                
          <div class="col-md-7">
              <div class="section-title">
                <h2>User Experience Services</h2>
              </div>          
          </div>
          <p class="lead header_p">
                User Experience(UX) focus in understanding everything about USERS, what they Need and They Value, their abilities and Limitations
                UX is the only and best practices to improve the quality of the user Interaction is about how feel the user interacting with the website application, mobile application or desktop application, 
                not matte what kind of the application 
          </p>  
        </div>
    </div> 
        <!--  <br>        
     <div class="container">
        <section class="d-flex bg-light" id="feature-cards">
            <div class="container d-flex justify-content-center">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-12 align-self-center">                           
                        <div class="row">
                          <div class="col-lg-6 col-md-4">
                            <div class="card mb-4  border-0">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col mx-auto text-center">
                                            <i style="color:#29abe0;font-size:4em;" class="fa fa-laptop" aria-hidden="true"></i> 
                                            <img src="/assets/img/UserResearch.jpg"  class="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12 ">
                                            <p class="subheading text-info">User Research & Testing</p>
                                             
                                                <ul class="fa-ul">
                                                  <li><i class="fa-li fa fa-check-square"></i>User Interviews.</li>
                                                  <li><i class="fa-li fa fa-check-square"></i>Usability Testing.</li>
                                                  <li><i class="fa-li fa fa-check-square"></i>Customer Jounary Mappings.</li>
                                                  <li><i class="fa-li fa fa-check-square"></i>User Flow, Screen Flows.</li>
                                                  <li><i class="fa-li fa fa-check-square"></i>UX Audits.</li>
                                                  <li><i class="fa-li fa fa-check-square"></i>Information Arquitecture.</li>                                   
                                                </ul> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-4">
                          <div class="card mb-4  border-0">
                              <div class="card-body">
                                  <div class="row">
                                      <div class="col mx-auto text-center">
                                          <i style="color:#29abe0;font-size:4em;" class="fa fa-object-ungroup" aria-hidden="true"></i>  
                                          <img src="/assets/img/IA_Wireframe.jpg"  class="img-fluid" alt=""/>
                                      </div>
                                  </div>
                                  <div class="row mt-3">
                                      <div class="col-lg-12 ">
                                          <p class="subheading text-info">UX design</p>
                                          <p class="parrafo"></p>
                                          <ul class="fa-ul">
                                            <li><i class="fa-li fa fa-check-square"></i> User Interface Design.</li>
                                            <li><i class="fa-li fa fa-check-square"></i> Interaction Design.</li>
                                            <li><i class="fa-li fa fa-check-square"></i> Low and Hight fidelity Prototype.</li>
                                            <li><i class="fa-li fa fa-check-square"></i> Rapid Prototype.</li>
                                            <li><i class="fa-li fa fa-check-square"></i> Wireframes and Mockups.</li>
                                            <li><i class="fa-li fa fa-check-square"></i> Themes CMS.</li>                                   
                                          </ul> 
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                        </div>
                      </div>  
                    </div>  
                  </div>  
                </section>
              </div>   -->              
    

    <div class="section-block-grey">
      <div class="container">
          <!--<div class="section-heading center-holder">
              <h2 >Our Core technologies</h2>
              <div class="section-heading-line"></div>
              <p>
                  <br></p>
          </div>-->
          <div class="row mt-60">
              <div class="col-md-6 col-sm-12 col-12">
                  <div class="serv-section-2">
                      <div class="serv-section-2-icon"> <i class="fas fa-chalkboard-teacher fa-5x"></i> </div>
                      <div class="serv-section-desc">
                          <h4>User Research & Testing</h4>                          
                        </div>
                      <div class="section-heading-line-left"></div>
                      <p align="left">All digital project start by a deep research using the proper tools  with a deep analysis gathering all user requirements to create a friendly design for all users.</p>
                      <ul class="fa-ul">
                        <li><i class="fa-li fa fa-check-square"></i>User Interviews.</li>
                        <li><i class="fa-li fa fa-check-square"></i>Usability and Accessibility Testing.</li>
                        <li><i class="fa-li fa fa-check-square"></i>Customer Jounary Mappings.</li>
                        <li><i class="fa-li fa fa-check-square"></i>User Flow, Screen Flows.</li>
                        <li><i class="fa-li fa fa-check-square"></i>UX Audits.</li>
                        <li><i class="fa-li fa fa-check-square"></i>Card Sorting.</li>  
                        <li><i class="fa-li fa fa-check-square"></i>Information Arquitecture.</li>                                   
                      </ul> 
               
                  </div>                    
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                  <div class="serv-section-2 serv-section-2-act">
                      <div class="serv-section-2-icon serv-section-2-icon-act"> <i class="fab fa-sketch fa-5x"></i> </div>
                      <div class="serv-section-desc">
                          <h4>User Design</h4>
                        
                        </div>
                      <div class="section-heading-line-left"></div>
                      <p align="left">Follow the user research process we will design the product, together with end user delivering a customized user experience aprove by them .</p>
                      <ul class="fa-ul">
                        <li><i class="fa-li fa fa-check-square"></i> User Interface Design.</li>
                        <li><i class="fa-li fa fa-check-square"></i> Interaction Design.</li>
                        <li><i class="fa-li fa fa-check-square"></i> Low and Hight fidelity Prototype.</li>
                        <li><i class="fa-li fa fa-check-square"></i> Rapid Prototype.</li>
                        <li><i class="fa-li fa fa-check-square"></i> Wireframes and Mockups.</li>
                        <li><i class="fa-li fa fa-check-square"></i> Themes CMS.</li>                                   
                      </ul> 
                      <br>
                  </div>
              </div>
            
           
        
     </div>
      </div>
  </div>
    <div class="jumbotron jumbotron-fluid question">
      <div class="container">
        <h3 class="text-center">Working together for Better Design?</h3>
        <h3 class="text-center">DESIGN THINKING FRAMEWORK</h3>
        <p class="lead">
         We use the methodology Design Thinking Framework in our customer experience project because is one the methodology used to solve complex problems to find a desirable solutions.
        </p>
          <div class="row">
          <div class="col-sm-4 hello-image">
              <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
              
          </div>
              <div class="col-sm-8">   
              <h2 class="display-4">Step 1</h2>
               <h4 class="subheading">EMPATHISE.</h4>
                 <p class="lead">Conduct research to develop and understanding of users.
                 </p>
          </div>
      </div>
      
          <div class="row">
          <div class="col-sm-4 hello-image">
              <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
              
          </div>
              <div class="col-sm-8">   
              <h2 class="display-4">Step 2</h2>
               <h4 class="subheading">IDEATE</h4>
                          <p class="lead">Generate of all your ideas, Crazy and Creative ideas, Brainstorm ideas good & bad</p>
          </div>
      </div>
             <div class="row">
          <div class="col-sm-4 hello-image">
              <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
              
          </div>
              <div class="col-sm-8">   
              <h2 class="display-4">Step 3</h2>
               <h4 class="subheading">DEFINE.</h4>
                          <p class="lead">Combine all Research, Observe, Creating a point of view where user's problem exist</p>
          </div>
      </div>
      <div class="row">
        <div class="col-sm-4 hello-image">
            <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
            
        </div>
            <div class="col-sm-8">   
            <h2 class="display-4">Step 4</h2>
             <h4 class="subheading">TEST.</h4>
                        <p class="lead">Research, Observe, Understand, Creating a point of view</p>
        </div>
    </div>
    <div class="row">
      <div class="col-sm-4 hello-image">
          <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
          
      </div>
          <div class="col-sm-8">   
          <h2 class="display-4">Step 5</h2>
           <h4 class="subheading">PROTOTYPE.</h4>
                      <p class="lead">Build real a representation for all your ideas</p>
      </div>
  </div>
      </div>
    </div>
    <app-getintouch [pageindex]=pagenumber></app-getintouch>
   
      <br>
     
<app-footer></app-footer>