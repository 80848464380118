import { Time } from "@angular/common";

export class Contact {
    id: string;
    fullname: string;
    email: string;
    phone: string;     
    url: string;
    subject: string;
    message: string;
    datepick: Date;
    timepick: Time;
}
