<!--The content below is only a placeholder and can be replaced.-->
<app-header></app-header>
<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
  <div class="container text-center">
    <h1 class="styleheader display-4 mb-5"><strong>IT Business Solutions</strong></h1>
    <p class="downtext  mb-5">Successful Solutions for Any Technology Situation is Our Guaranteed</p>
    
     
    <div class="border-btn align-items-center">
      <div class="row align-items-center justify-content-center">
        <a class="btnexplore" routerLink="/services">Explore our services </a>        
      </div>
   </div>
  </div>
  
</div> 
<br>
<!--<div class="container">    
    <div class="col-md-12">
      <div class="section-title">
         <h2>About us</h2>
      </div>
   </div>   
       <div class="row justify-content-center">
         <div class="col-lg-12">
           <div class="aboutus">Hard & Soft Solutions inc is a locally owned computer consultant in 
             the Ottawa region since 2009.<br> 
             we provide Technology solutions in Computer Repair,Computer Maintenance,Computer Network, 
             Custom Software and Web Apps Development including Mobile Application And UX Design.
            <br>
            We have been privileged to keep in the market by over 15 years in Canada. 
            We are proud of our reputation for providing superior service to our customers.
            That reputation is the result of our firm loyalty to our customers. 
          </div>
         </div>         
       </div>
     </div>-->
     <section>
      <div class="container">
          <div class="row h-100 justify-content-center align-items-center">
              <div class="col-lg-6  col-md-8 ">
                  <div class="section-title">
                      <h2 class="darkcolor bottom20 text-capitalize">About us</h2>
                      
                  </div>
                 
              </div>
              <div class="col-lg-10    col-md-10 justify-content-center">
                <div class="aboutus">Hard & Soft Solutions inc is a locally owned computer consultant in 
                  the Ottawa region since 2009.<br> 
                  we provide Technology solutions in Computer Repair,Computer Maintenance,Computer Network, 
                  Custom Software and Web Apps Development including Mobile Application And UX Design.
                 <br>
                 We have been privileged to keep in the market by over 15 years in Canada. 
                 We are proud of our reputation for providing superior service to our customers.
                 That reputation is the result of our firm loyalty to our customers. 
               </div>
              </div>
          </div>
         </div>
     </section>  
<br><br>
<div class="container  align-items-center  h-100 q">
  <h2 class="sub">We provide services to</h2>
  <div class="row h-100 justify-content-center align-items-center">		 
    <div class="col-lg-4  col-sm-3 col-md-4">
      <div class="circle ">
         <h2><i class="fas fa-briefcase"></i><p>Small Business</p></h2> 
      </div>
    </div>
    <div class="col-lg-4  col-sm-3 col-md-4">
      <div class="circle">
        <h2><i class="fas fa-house-user"></i><p>Residential Customers</p></h2>
      </div>
    </div>
    <div class="col-lg-4  col-sm-3 col-md-4">
      <div class="circle">
        <h2><i class="fas fa-landmark"></i><p>Public Services</p></h2>
      </div>
    </div>
    
  </div>
  </div>
  <br><br>
<!--<div class="container-fluid jumbotron-info align-items-center  h-100 q">
  <h2 class="sub">What services we offer in technology?</h2>
  <div class="row offset-2">		 
    <div class="col-sm-3">
      <div class="circle ">
         <h2>1<p>Computer Repair</p></h2> 
      </div>
    </div>
    <div class="col-sm-3">
      <div class="circle">
        <h2>2<p>Computer Maintenance</p></h2>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="circle">
        <h2>3<p>Network Solutions</p></h2>
      </div>
    </div>
    
  </div>
  <div class="row  offset-2 ">		 
    <div class="col-sm-3">
      <div class="circle ">
         <h2>4<p>UX Designer</p></h2> 
      </div>
    </div>
    <div class="col-sm-3">
      <div class="circle">
        <h2>5<p>Applications Development</p></h2>
      </div>
    </div>  
    
  </div>
  </div> -->
<hr class="style8">
<br>
<div class="jumbotron jumbotron-sm">
<div class="container">

  <div class="row">
    <div class="col-lg-4 text-center">

     <i class="fa fa-users" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>Full Services in Technology</h4>
      <p align="left">We Provide specialized technology-oriented solutions by combining the processes and functions of Software industries such as Mobile, Web and Desktop Applications, Hardware and Networks Integration.</p>

    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4 text-center">
      <i class="fa fa-stopwatch-20" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>20 years Experience </h4>
      <p align="left">We offer a wide a variety of services which are well know in the Technology Market. With 20 years experience Hard & Soft Solutions inc stands out because it delivers top-quality services in technology.</p>

    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4 text-center">
       <i class="fa fa-thumbs-up" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>100% Customer Satisfaction</h4>
      <p align="left">Our dedication and professionalism to our work has secured us our Goal, provide to you with the best quality product and services possible. We are not Happy Unless you are Happy.</p>

    </div><!-- /.col-lg-4 -->
  </div><!-- /.row -->
</div>
</div>

<br>

<app-getintouch  ></app-getintouch>
  

    <app-footer></app-footer>
