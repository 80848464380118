import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoadedDevelopmentSolution:Boolean=false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    // $(document).ready(() => { 
     
    //   $('.navbar-nav .nav-link').click(function(){      
    //     $('.navbar-nav .nav-link').removeClass('active');
    //     $(this).addClass('active');
    // })
    // });
    const p=Number(this.route.snapshot.queryParamMap.get('page'));
    if ((p==5)  || (p==9)) {
      this.isLoadedDevelopmentSolution=true;
    }
     
    
  }

}
