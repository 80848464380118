<app-header></app-header> 
<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
  <div class="container text-center">
          <h1 class="styleheader display-4"><strong> Seek More Possibilities of Collaboration! </strong></h1> 
      </div>
</div> 
<div class="container">
  <div class="row  h-100 justify-content-center align-items-center">                      
    <div class="col-md-7">             
        <h1 class="card-title">Get Access to Wonderful<h3>Benefits joining our</h3>       
          <h1 class="styleheader1 display-4"><strong> Referral Program </strong></h1> </h1>    
    </div>
    <h3>      
      </h3>  
  </div>  
</div>
<div class="container-fluid jumbotron-info">
<div class="row ">		 
  <div class="col-sm-3">
    <div class="circle ">
       <h2>1<p>No Investment</p></h2> 
    </div>
  </div>
  <div class="col-sm-3">
    <div class="circle">
      <h2>2<p>Earn Gift Cards 24/7</p></h2>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="circle">
      <h2>3<p>No website need it</p></h2>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="circle">
     <h2>4<p>No Customer Contact</p></h2>
    </div>
  </div>
</div>
</div>

  <h1 class="card-title">How to use<h3>Referral Program to Boost Your Income</h3></h1>
  <div class="jumbotron jumbotron-fluid question">
    <div class="container">   
        <div class="row">
        <div class="col-sm-4 hello-image">
            <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->            
        </div>
            <div class="col-sm-8">   
            <h2 class="display-4">Step 1</h2>
             <h4 class="subheading">Fill out the form.</h4>
               <p class="lead">If you have friends or relatives, where them looking for a technnology solutions. Please <a href="http://apps.hardandsoft.ca/referral.html" target="_blank">complete the referral Form </a>and send to us.
               </p>
        </div>
    </div>
    
        <div class="row">
        <div class="col-sm-4 hello-image">
            <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
            
        </div>
            <div class="col-sm-8">   
            <h2 class="display-4">Step 2</h2>
             <h4 class="subheading">We Will Contact with yourr referral.</h4>
                        <p class="lead">We will contact each referral from you.</p>
        </div>
    </div>
           <div class="row">
        <div class="col-sm-4 hello-image">
            <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
            
        </div>
            <div class="col-sm-8">   
            <h2 class="display-4">Step 3</h2>
             <h4 class="subheading">Gift Card for you.</h4>
              <p class="lead">if your referral enters into an agreement with us for a services, we will reward you for each one 10$ CAD(for computer repair or maintenance) for other services will be more much from Digital Amazon Gift Card.</p>
        </div>
    </div>
    
    </div>

</div>
 <br>
<app-getintouch [pageindex]=pagenumber></app-getintouch>
<app-footer></app-footer>
