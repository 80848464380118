<app-header></app-header>
 
        <div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
          <div class="container text-center">
                  <h1 class="styleheader display-4"><strong>  We Focus on you technology with a powerfull preventive maintenance plan</strong></h1> 
              </div>
        </div>
  
        <br/>    
        <div class="container">
            <div class="row  h-100 justify-content-center align-items-center">                     
              <div class="col-md-7"> 
              <div class="section-title">
                <h2>Computer Maintenance services</h2>
              </div> 
            </div>
              <p class="lead header_p">
                  Computer running any operating system need frequently maintennace and updating,New vulneravility are always found and patches.
                  keeping your computer up-to-date with an special services packs and crtical updates should be a hight piority to any computer user or business users. 
                  </p>  
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                    <div class="heading-title text-center wow fadeInUp" data-wow-delay="300ms">
                        <h2 class="darkcolor bottom20 text-capitalize">Benefits of Computer Maintenance </h2>
                        <p class="bottom0">Like a maintennace in your car, House or in your body, computer maintennace will provide
                          a good benefits for your device and for you savin time and money, let me show just 5 benefits when your device get a periodically maintenance</p>
                    </div>
                </div>
            </div>
                    <div class="col-lg-12 offset-lg-2">                             
                                         <div class="row box">                                     
                                            <div class="col-lg-2 col-sm-6">
                                                <div class="circle-tile ">
                                                  <div class="circle-tile-heading white-color"><i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></div>                                                  <div class="circle-tile-content white-color">
                                                    <div class="circle-tile-description text-faded">Longer Computer Life</div>
                                                     
                                                     
                                                  </div>
                                                </div>
                                              </div>
                                               
                                              <div class="col-lg-2 col-sm-6">
                                                <div class="circle-tile ">
                                                  <div class="circle-tile-heading white-color"><i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></div>
                                                  <div class="circle-tile-content white-color">
                                                    <div class="circle-tile-description text-faded">Less lost Files </div>
                                                     
                                                    
                                                  </div>
                                                </div>
                                              </div> 
                                              <div class="col-lg-2 col-sm-6">
                                                <div class="circle-tile ">
                                                  <div class="circle-tile-heading white-color"><i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></div>
                                                  <div class="circle-tile-content white-color">
                                                    <div class="circle-tile-description text-faded">Protection Again threats </div>
                                                     
                                                    
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-lg-2 col-sm-6">
                                                <div class="circle-tile ">
                                                  <div class="circle-tile-heading white-color"><i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></div>
                                                  <div class="circle-tile-content white-color">
                                                    <div class="circle-tile-description text-faded">Less equipment failure</div>
                                                     
                                                    
                                                  </div>
                                                </div>
                                              </div>   
                                        </div>                      
                    </div>
                </div>                
                <br>
                <!-- <div class="container">
                    <div class="row  h-100 justify-content-center align-items-center">                     
                      <div class="col-md-7">             
                          <h3 class="card-title">Some of the computer maintenance tasks</h3>                          
                      </div>
                      <h3>                            
                      </h3>  
                    </div>
                </div> -->
                
                  <!--<div class="row  h-100 justify-content-center align-items-center"> --> 
                    <!--<h3 class="txtcenter">Some of the computer maintenance tasks</h3> -->                   
                    <!-- <div class="col-md-7"> -->            
                          <!--   <h3 class="card-title">Some of the computer maintenance tasks</h3> -->                         
                    <!--</div> -->
                 
                  
                    <!-- Feature Cards -->
                    
                    <div class="container">
                    <section class="d-flex bg-light" id="feature-cards">
                        <div class="container d-flex justify-content-center">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-12 align-self-center">                           
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="card mb-4">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col mx-auto text-center">
                                                            <i style="color:#29abe0;font-size:4em;" class="fa fa-laptop" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 text-center">
                                                            <p class="subheading text-info">Deep Hardware Cleaning</p>
                                                            <p class="parrafo">When dust accumulates inside the computer, it prevents the flow of air and reduces the cooling of the components
                                                                , several compnents are important to keep clean: Heat sink and fans assembly, RAM, Adapters cards, Motherboard, Fans, Power Supply, Internal drivers and keyboard.</p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="card mb-4">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col mx-auto text-center">
                                                            <i style="color:#29abe0;font-size:4em;" class="fa fa-tasks" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 text-center">
                                                            <p class="subheading text-info">Update operating system </p>                                                    
                                                            <p class="parrafo">We will update all service packs, security updates, and all types of update incluying all your programs, including Internet Explorer, Microsoft Office</p>
                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="card mb-4">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col mx-auto text-center">
                                                            <i style="color:#29abe0;font-size:4em;" class="fa  fa-certificate" aria-hidden="true"></i>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 text-center">
                                                            <p class="subheading text-info">Verify protection </p>
                                                            <p class="parrafo">We clean, Restore and Optimize your computer with a high and deep  diagnoses optimizing all file system, hard driver and Windows Registry in your computer desktop or laptop. </p>
                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col mx-auto text-center">
                                                            <i style="color:#29abe0;font-size:4em;" class="fa fa-hdd" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-12 text-center">
                                                            <p class="subheading text-info">Hard driver Defragmentation</p>
                                                            <p class="parrafo">Sometimes the computer's running slower than normal, you might be able to speed back up by defragmenting that hard drive.
                                                                Defragmenting is beneficial for HDD it brings files togther instead of scattering them so that the device's read-write head doesn't have to move around as much when accessing files.   </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                           </div>
                        </div>                         
                    </section>                
                </div>
                <section>
                    <div class="container">
                        <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-lg-6  col-md-8 ">
                                <div class="heading-title text-center wow fadeInUp" data-wow-delay="300ms">
                                    <h2 class="darkcolor bottom20 text-capitalize">Our computer maintenance strategy</h2>
                                    
                                </div>
                               
                            </div>
                            <div class="col-lg-10    col-md-10 justify-content-center">
                                <p class="lead header_p">When you hear that your devices need maintenance is no enought,
                                    you need a to choose  the right and efficently maintenance stretegy, but for that you need to know your options.
                                    this is the most strategies computer maintennace types requested, Differente computer will break down for differentreasons and different ways.

                                </p>
                            </div>
                        </div>
                        
                            <div class="row mbr-justify-content-center">
                                <div class="col-lg-6 mbr-col-md-10">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-toolbox fas"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Predictive Maintenance</h2>
                                            <p class="parrafo">  PC users turn to diagnostic tools to prevent possible problems with the computer.
                                            The diagnostic tools monitor computer systems and check whether the functioning is normal.
                                            For example, such tools control and inform you about the temperature of the CPU, battery levels, and many other features</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mbr-col-md-10">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-fist-raised fas"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Preventive Maintenance
                                                
                                            </h2>
                                            <p class="parrafo">This type is used to prevent possible failures, improve the functioning of a system and lengthen the life of various components in the computer. The benefits of this type of maintenance are that it allows one to identify weak points in the system and lead to a lesser amount of repairs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 mbr-col-md-10">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-tools fas"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5">Corrective maintenance
                                                 
                                            </h2>
                                            <p class="parrafo">If the preventive and predictive maintenance don’t work and a problem with the computer occurs,
                                            it’s time to turn to corrective maintenance. What does it mean and include? If the operating system fails and we want it to work again in the same condition, 
                                            we apply the necessary measures, depending on the case, and use the PC further with no failures.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                   
                    </div>                    
                    </section>
                <app-getintouch [pageindex]=pagenumber></app-getintouch>
            <br>
            <div class="jumbotron jumbotron-fluid question">
              <div class="container">
                <h3 class="txtcenter">Computer maintenance workflow</h3>
                <p class="lead">
                 We can help you save an impressive sum of money and a lot of hedeaches and help you work more efficiently.
                 We describe below the steps you need to do use our computer maintenance services. 
                </p>
                  <div class="row">
                  <div class="col-sm-4 hello-image">
                      <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                      
                  </div>
                      <div class="col-sm-8">   
                      <h2 class="display-4">Step 1</h2>
                       <h4 class="subheading">Contact US.</h4>
                         <p class="lead">Request computer maintenance should fill out the form or call us, one of the technichian will gave you a free estimated
                             price depending on the frequency and how many devices in total you want apply maintenance.
                         </p>
                  </div>
              </div>
              
                  <div class="row">
                  <div class="col-sm-4 hello-image">
                      <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->
                      
                  </div>
                      <div class="col-sm-8">   
                      <h2 class="display-4">Step 2</h2>
                       <h4 class="subheading">Legal Contracts.</h4>
                        <p class="lead">You must sign an agreement that follow the terms and prices according to the contract, 
                            the agreement includes the amount of devices to be repaired and the type of maintenance service.
                        </p>
                  </div>
              </div>
                     <div class="row">
                  <div class="col-sm-4 hello-image">
                      <!-- <img src="https://image.ibb.co/ctSLu9/Work_Section2_freelance_img3.png" class="rounded-circle img-response" alt=""/> -->                     
                  </div>
                            
                      <div class="col-sm-8">   
                      <h2 class="display-4">Step 3</h2>
                       <h4 class="subheading">Schedule service.</h4>
                          <p class="lead">Once the agreement is signed, maintenance will be performed following the date and time on the agreement.</p>
                  </div>
              </div>              
              </div>
            </div>
          <br>
    <app-footer></app-footer>