import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-getintouch',
  templateUrl: './getintouch.component.html',
  styleUrls: ['./getintouch.component.css'],
})
export class GetintouchComponent implements OnInit {
  @Input() pageindex: number;
  title: string;
  body: string;
  constructor() {}

  ngOnInit() {
    console.log(this.pageindex);
    // tslint:disable-next-line:max-line-length
    this.body =
      'To Know if our services are Right for you give us 10 minutes for a brief fact finding conversation. Just click on the Button to get in touch with us';
    if (this.pageindex === 1) {
      this.title = 'Looking Computer Repair Services?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 2) {
      this.title = 'Looking for Computer Maintenance Services?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 3) {
      console.log('en ux design');
      this.title = 'Looking for Network Computer Services?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 4) {
      this.title = 'Looking for UX Agency?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 5) {
      console.log('en ux design');
      this.title = 'Looking for Application Development Services?';
      //  this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 6) {
      console.log('en ux design');
      this.title = 'Looking for Business Intelligence Agency?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 7) {
      console.log('en ux design');
      this.title = 'Need any services from us?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 8) {
      console.log('en ux design');
      this.title = 'Any Question about Referral Program?,Contact us Right Now!';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
    if (this.pageindex === 9) {
      console.log('en ux design');
      this.title = 'Are you interesting in one of our solutions?';
      // this.body = 'If you need of UX user experience design, we would be happy to help working together hand by hand with you.';
    }
  }
}
