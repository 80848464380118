<div class="Features-section paddingTB60 ">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
              <h3 class="text-center">Why you should considerate us?</h3>
              <p>Many companies said they are the number One on the IT market offering the best services, but before contact them that you look our customer consdieration</p>
               
      </div>
    </div>
      <div class="row">
              <div class="col-sm-6 col-md-3">
                <div class="col-md-12 feature-box">
                    <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                  <h4>90 day warranty services</h4>
                  <p align="left">We know technology and we know something can happens next day, so the services is cover for 90 day you never pay again for that time.</p>
                  
  
                </div>
              </div> <!-- End Col -->
              <div class="col-sm-6 col-md-3">
                  <div class="col-md-12 feature-box">
                      <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                  <h4>Gift card Rewards</h4>
                  <p align="left">For each friends, family or any know, you recommend our services and them hiring our services for a Computer Repair/maintenance service your will recived a gift card 20$ from amazon. </p>
                    
                </div>
              </div> <!-- End Col -->	
              
              <div class="col-sm-6 col-md-3">
                  <div class="feature-box">
                      <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                  <h4>Free Diagnostic</h4>
                  <p align="left">We offer FREE DIAGNOSTIC for all our clients, other companies charge until 50$ just for the diagnostic.</p>
                    
                </div>
              </div> <!-- End Col -->
              <div class="col-sm-6 col-md-3">
                  <div class="col-md-12 feature-box">
                      <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                  <h4>Discount services</h4>
                  <p align="left">We offer discount services for Senior 65+, Military Services and Students, With a valid identification. </p>
                     
                </div>
              </div> <!-- End Col -->
            </div> 
            
    
           
  
  <div class="row">
  <div class="col-sm-6 col-md-3">
      <div class="col-md-12 feature-box">
          <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
      <h4>Mobile services</h4>
      <p align="left">If you can't came to us.We come to you!, Onsite Computer Repair services Residential & Small Business</p>
         
    </div>
  </div> <!-- End Col -->

      <div class="col-sm-6 col-md-3">
        <div class="col-md-12 feature-box">
            <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Not Hidden Fee</h4>
          <p align="left">We don't  have any hidden fee, since beggining client will know everything.</p>
           

        </div>
      </div> <!-- End Col -->
      <div class="col-sm-6 col-md-3">
          <div class="col-md-12 feature-box">
              <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Free Loan Computer</h4>
          <p align="left">Meanwhile we fix your computer we can borrow you one. </p>
             
        </div>
      </div> <!-- End Col -->	
      
      <div class="col-sm-6 col-md-3">
          <div class="feature-box">
              <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Not Fix=Not charge</h4>
          <p align="left">If we don't fix, you don't pay anything. </p>
             
        </div>
      </div> <!-- End Col -->
      </div>
      <div class="row">
      <div class="col-sm-6 col-md-3">
          <div class="col-md-12 feature-box">
              <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Free pickup & Delivery</h4>
          <p align="left">We pickup and delivery  your device on your location without any cost for the clients on Ottawa region. </p>
            
        </div>
      </div> <!-- End Col -->
   
      <div class="col-sm-6 col-md-3">
          <div class="col-md-12 feature-box">
              <i style="color:#526168;font-size:3em" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Fast & Friendly Services</h4>
          <p align="left">Usually we fix the issues in 4 hours, but everything depend if the device need replace parts we don't have in stuck, in that way we have to  make the order.</p>
             
        </div>
      </div> <!-- End Col -->
      <div class="col-sm-6 col-md-3">
          <div class="col-md-12 feature-box">
              <i style="color:#526168;font-size:3em;" class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
          <h4>Fixing all models</h4>
          <p align="left">We fix New/Old Devices any Models as HP, Samsung, Acer, LG, Satellite, Dell, Lenovo, eMachines, gateway and more... </p>
             
        </div>
      </div> <!-- End Col -->
    </div> 
</div><!-- container-->
 
  </div><!-- features-->
