 
 
<div class="container"> 
      <div class="style-5 section-padding">        
             <div class="row">
                <div class="col-lg-12">
                   <div class="section-title">
                      <h2>What we Do?</h2>
                   </div>
                </div>
                <p class="header_p lead">Hard & Soft Solutions inc embrance all faces of the IT Industry at once fixed price for any services
                     since the begining to ensure our clients stay on the budget,assure time schedule,guarantee an efficient use of resources
                      and manintain management and focus on core business.Our business focus in providing top of the line customer service with a high level of professionalism.
                </p>
             </div>
             <br><br>
             <!--<div class="row">
                <div class="col-lg-4 col-md-4 col-sm-3" >
                   <div class="single-service">
                     <i class="fa fa-laptop fa-8x"></i>
                      <h3 class="style-5">Computer Repair</h3>
                      <p class="parrafo">We Offer a wide range of hardware services, virus removal, operating system installation, black screen, GPU problems, screen replacment for all models of Desktop and Laptop.</p>
                       
                      <a href="javascript:void(0);" (click)="GoToRepairComponent()"  class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-3">
                   <div class="single-service">
                     <i class="fa fa-laptop fa-6x"></i>
                      <h3>Computer Maintenance</h3>
                      <p class="parrafo">We will keeping your computer hardware in a good shape of physical health at least one time peer year in order to be operational.</p>
                      
                      <a href="javascript:void(0);"   (click)="loadMaintenanceComponent()" class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-3">
                   <div class="single-service">
                     <i class="fa fa-sitemap fa-6x"></i> 
                      <h3>Network Solutions</h3>
                      <p  class="parrafo">We design, Install and support complete network solutions for Residentials, small and mid-size business including Network consulting.</p>
                       
                      <a href="javascript:void(0);" (click)="loadNetworkComponent()" class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div>
             </div>-->
             <!--<div class="row">
                <div class="col-lg-4 col-md-4 col-sm-3 " >
                   <div class="single-service ">
                     <i class="fa fa-object-ungroup fa-8x"></i>
                      <h3>User Designer (UX)</h3>
                      <p class="parrafo">We design process focus on the interaction between real users and their products or services, such as Website and Apps, manipulating user behavior.</p>
                      
                      <a href="javascript:void(0);" (click)="loadExperienceDesignComponent()" class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div>
                <div class="col-lg-4 col-md-4  col-sm-3  ">
                   <div class="single-service">
                      <i class="fa fa-laptop-code fa-8x"></i>
                      <h3>Applications Development</h3>
                      <p class="parrafo">We offer a complete development solutions for Web, Desktop and Mobile applications for cross-platform including UX Design if need it.</p>
                      <br>
                      <a href="javascript:void(0);" (click)="loadDevelopmentComponent()" class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div> -->
                <!-- <div class="col-md-4">
                   <div class="single-service">
                     <i class="fa fa-balance-scale fa-8x"></i>
                      <h3>Digital Marketing</h3>
                      <p class="parrafo">We can create and develop Marketing strategy on the Social Network, Publicity Camping, Marketing Email, A/B Testing</p>
                      <a href="javascript:void(0);" (click)="loadBusinessIntelligenceConsultingComponent()" class="read-more border-btn " >Discover Solutions</a>
                   </div>
                </div> -->
             <!--</div>-->
          <!-- </div> -->
            </div>
     <!-- Section Style 5 End -->
        
            <!-- <div class="section-headerwebsite">
                <h2 class="dark-text"></h2>
               
            </div>-->
         
</div>
<section class="details-card">
   <div class="container">
       <div class="row">
           <div class="col-lg-4 col-md-4 col-sm-4">
               <div class="card-content">
                   <div class="card-img-top">
                       
                       <img src="assets/img/pcrepair.png"  class="img-fluid" alt=""/> 
                        
                   </div>
                   <div class="card-desc">
                       <h3> Computer Repair</h3>
                       <p class="parrafo">We Offer a wide range of hardware services, virus removal, operating system installation, black screen, GPU problems, screen replacement for all models.</p>
                             
                           <a href="javascript:void(0);" (click)="GoToRepairComponent()"  class="read-more border-btn " >Discover Solutions</a>
                   </div>
               </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-4">
               <div class="card-content">
                   <div class="card-img-top">
                     <img src="assets/img/pcmaintenance.png"  class="img-fluid" alt=""/> 
                        
                   </div>
                   <div class="card-desc">
                       <h3>Computer Maintenance</h3>
                       <p class="parrafo">We will keeping your computer hardware in a good shape of physical health at least one time peer year in order to be operational.</p>
                       <a href="javascript:void(0);"   (click)="loadMaintenanceComponent()" class="read-more border-btn " >Discover Solutions</a>  
                   </div>
               </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-4 ">
               <div class="card-content">
                   <div class="card-img-top">
                     <img src="assets/img/pcnetwork.png"  class="img-fluid" alt=""/> 
                        
                   </div>
                   <div class="card-desc">
                       <h3>Network Solutions</h3>
                       <p  class="parrafo">We design, Install and support complete network solutions for Residentials, small and mid-size business including Network consulting.</p>
                       <a href="javascript:void(0);" (click)="loadNetworkComponent()" class="read-more border-btn " >Discover Solutions</a>  
                   </div>
               </div>
           </div>
       </div>
       <br/>
       <div class="row">
         <div class="col-lg-4 col-md-4   col-sm-4 ">
             <div class="card-content">
                 <div class="card-img-top">
                      
                     <img src="assets/img/userdesign.png"  class="img-fluid" alt=""/> 
                      
                 </div>
                 <div class="card-desc">
                     <h3> User Designer(UX)</h3>
                     <p class="parrafo">We design process focus on the interaction between real users and their products or services, such as Website and Apps, manipulating user behavior.</p>
                     <a href="javascript:void(0);" (click)="loadExperienceDesignComponent()" class="read-more border-btn " >Discover Solutions</a>   
                 </div>
             </div>
         </div>
         <div class="col-lg-4 col-md-4  col-sm-4">
             <div class="card-content">
                 <div class="card-img-top">
                  <img src="assets/img/development.png"  class="img-fluid" alt=""/> 
                      
                 </div>
                 <div class="card-desc">
                     <h3>Application Development</h3>
                     <p class="parrafo">We offer a complete development solutions for Web, Desktop and Mobile applications for cross-platform including UX Design if need it.</p>
                     <a href="javascript:void(0);" (click)="loadDevelopmentComponent()" class="read-more border-btn " >Discover Solutions</a>  
                 </div>
             </div>
         </div>
        
     </div>
   </div>
</section>