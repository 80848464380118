<footer id="myFooter">

        <div class="row d-flex justify-content-center  h-100">
             
          <div class="col-lg-3 col-md-3 col-sm-2 ">
              <h5>Company</h5>
              <ul>
                  <li><a routerLink="/">About us</a></li>                 
                  <li><a routerLink="/industries"[queryParams]="{page:7}">Industries</a></li>
                  <li><a routerLink="/Partners"[queryParams]="{page:8}">Referral Program</a></li>
                  <li><a routerLink="/oursolutions" [queryParams]="{page:9}">Our Solution</a></li>
                  <li><a routerLink="/contact">Get in touch</a></li>
              </ul>
          </div> 
            <div class="col-lg-3 col-md-3 col-sm-2 ">
                <h5>Services</h5>
                <ul>
                    <li><a routerLink="/repair">Computer Repair</a></li>
                    <li><a routerLink="/maintenance">Computer maintenance</a></li>
                    <li><a routerLink="/networksolutions">Computer Network</a></li>
                    <li><a routerLink="/userexperience">(UX)User Experience Designer</a></li>
                    <li><a routerLink="/development">Applications Development</a></li>  
                </ul>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-2">                      
                        <h5>Contact us</h5>
                        <ul>  
                      <li >
                        <a href="tel:18668320692" ><i class="fa fa-phone ml-3"></i> (1866)-832-0692 </a>
                      </li>
                      <li >
                        <a  href="tel:6136276680"><i class="fa fa-phone ml-3"></i> (613) 627-6680</a>
                      </li>
                      <li>
                           <a href="mailto:customer.care@hardandsoft.ca"><i class="fas fa-envelope-square ml-3"></i> customer.care@hardandsoft.ca</a>
                      </li>
                    </ul>
                    <h5>Address</h5> 
                    <ul>
                        <i class="fas fa-map-marker-alt"> 1064 Candlewood st, Ottawa, Ontario, Canada, K4A5E9</i>  
                    </ul>
                  
                   
            </div>
            <div class="col-lg-3 col-md-3 col-sm-2">           
                <ul>                   
                    <div class="text-center center-block">
                        <h5>Follow us on</h5>
                        <a href="https://www.facebook.com/hardandsoftsolutions"><i class="fab fa-facebook-square fa-3x ml-3" aria-hidden="true"></i></a>
                       <a href="https://twitter.com/hardandsoftsol"><i class="fab fa-twitter-square fa-3x ml-3"></i></a>                       
                      
                      </div>  
                   </ul>
                   <!--<ul>                   
                    <div class="text-center center-block">
                        <h5>Share us on</h5>
                        <a href="https://www.facebook.com/hardandsoftsolutions"><i class="fab fa-facebook-square fa-3x ml-3" aria-hidden="true"></i></a>
                       <a href="https://twitter.com/bootsnipp"><i class="fab fa-twitter-square fa-3x ml-3"></i></a>                       
                      
                      </div> 
                   </ul>--> 
            </div>
        </div>    
        <div class="footer-bottom">
            <div class="container">
                        <div class="row">
                            <div class="col-sm-6 ">
                                <div class="copyright-text">
                                    <p>CopyRight © 2020 Hard & Soft Solutions</p>
                                </div>
                            </div> <!-- End Col -->
                            <div class="col-sm-6  ">
                                <div class="copyright-text pull-right">
                                    <p><a href="#">Privacy</a> | <a href="#">Terms & Conditions</a>  </p>
                                </div>					
                                                        
                            </div> <!-- End Col -->
                        </div>
                    </div>
        </div>
</footer>

