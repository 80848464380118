import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-networksolutions',
  templateUrl: './networksolutions.component.html',
  styleUrls: ['./networksolutions.component.css']
})
export class NetworksolutionsComponent implements OnInit {
  @Input() pagenumber: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
    console.log(this.route.snapshot.queryParamMap.get('page'));
  }

}
