import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-oursolutions',
  templateUrl: './oursolutions.component.html',
  styleUrls: ['./oursolutions.component.css']
})
export class OursolutionsComponent implements OnInit {
  @Input() pagenumber: number;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.pagenumber = Number(this.route.snapshot.queryParamMap.get('page'));
    console.log(this.route.snapshot.queryParamMap.get('page'));
  }

}
