import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-businessintelligence',
  templateUrl: './businessintelligence.component.html',
  styleUrls: ['./businessintelligence.component.css']
})
export class BusinessintelligenceComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    console.log(this.route.snapshot.queryParamMap.get('page'));
  }

}
