
<app-header></app-header> 
  <!-- <div class="row "> 
        <img class="img-fluid w-100" src="assets/img/network_solutions.jpg" alt="">
  </div> -->
  <div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
    <div class="container text-center">
            <h1 class="styleheader display-4"><strong>  Need Networking Solutions?</strong>
            <p>One Stop For you Network Needs</p> </h1>
        </div>
  </div>  
  <!-- <div class="container-fluid">   
        <div class="row">            
            <div class="btn-group btn-breadcrumb breadcrumb-negro">
                <a routerlink="/home" class="btn btn-primary"><i class="fa fa-home"></i></a>
                <a routerLink="/services" class="btn btn-info">Services</a>
                <a href="/networksolutions" class="btn btn-success">Network Solutions</a>                        
            </div>
      </div>
    </div> -->
    <br>      
    <div class="container">
            <div class="row  h-100 justify-content-center align-items-center">                      
              <div class="col-md-7"> 
                  <div class="section-title">
                    <h2>Network Solutions Services</h2>
                  </div>               
              </div>
              <p class="lead">
                    If your business needs reliable and secure network we can help you grow up your company and protect your data.
              </p>  
            </div>
        </div>
    <div class="container">
            <!-- Feature Cards -->
            <section class="d-flex bg-light" id="feature-cards">
                <div class="container d-flex justify-content-center">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-12 align-self-center">                           
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-sitemap" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Network design</p>
                                                    <p class="parrafo">We need understand the requirements of your future computer network for design a powerful Network not matte what if is for 10 user or 500 users we design a security network for your data with a hight traffic </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-plug" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Network Wired cat5/Cat6 Installation </p>                                                    
                                                    <p class="parrafo">We have all tools necesseary for install cat5/cat6 cable in your home or office, after to be clear with topology and designed network work for your property.</p>
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                              
                             </div>
                            </div>
                            <div class="col-12 align-self-center">   
                            <div class="row">  
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa  fa-wifi" aria-hidden="true"></i>                                                     
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Network wireles Installation</p>
                                                    <p class="parrafo">We offer professional Business-grade wireless networking installation services,
                                                            we can help you with any wireless device installation and configuration and determine the best wireless solution for you needs. </p>                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                                    <div class="col-12 col-lg-6">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col mx-auto text-center">
                                                        <i style="color:#29abe0;font-size:4em;" class="fa fa-server" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <p class="subheading text-info">Servers Installations</p>                                                    
                                                        <p class="parrafo">We offer IT Network Installation  & Support Services,we have a good experience designing and installing servers for a variety of business.

                                                        </p>
                                                         
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                            </div>  
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fas  fa-stethoscope" aria-hidden="true"></i>                                                     
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Network Diagnostic</p>
                                                    <p class="parrafo">Router wire or Wireless has a variety of useful options you can configure, without that options available your network won't have connection to Internet or with other device
                                                            ,if that configuration have issues and not secury will be easy for a hacker to destroy your network. </p>                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa  fa-unlock-alt" aria-hidden="true"></i>                                                     
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Router Setup Configuration</p>
                                                    <p class="parrafo">Configure your router to make network complete. we need to configure the router so that it can communicate 
                                                        with your network components with a hight security settings. </p>                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                         </div>
                         <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="card mb-4">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col mx-auto text-center">
                                                    <i style="color:#29abe0;font-size:4em;" class="fa fa-ethernet" aria-hidden="true"></i>                                                     
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12 text-center">
                                                    <p class="subheading text-info">Cable Management</p>
                                                    <p class="parrafo">We offers a wide variety of server rack cable management and cable Organizer options for data center,networking or IT closets
                                                        patch panel racks and servers racks. Rack cable management can improve airflow, assists in the reduction of downtime, limiting user errors mistake.
                                                         .</p>                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                         </div>
                            </div>
                        </div>
                    </div> 
            </section>
    </div>  
    <br>   
 <app-getintouch [pageindex]=pagenumber></app-getintouch>
      <br>  
   
<app-footer></app-footer>
