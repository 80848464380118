import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RepairComponent } from './pages/repair/repair.component';
import { HomeComponent } from './pages/home/home.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NetworksolutionsComponent } from './pages/networksolutions/networksolutions.component';
import { ItconsultingComponent } from './pages/itconsulting/itconsulting.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { UserexperienceComponent } from './pages/userexperience/userexperience.component';
import { OursolutionsComponent } from './pages/oursolutions/oursolutions.component';
import { BusinessintelligenceComponent } from './pages/businessintelligence/businessintelligence.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { AppComponent } from './app.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'repair',
    component: RepairComponent
  },

  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'networksolutions',
    component: NetworksolutionsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'itconsulting',
    component: ItconsultingComponent
  },
  {
    path: 'development',
    component: DevelopmentComponent
  },
  {
    path: 'userexperience',
    component: UserexperienceComponent
  },
  {
    path: 'oursolutions',
    component: OursolutionsComponent
  },
  {
    path: 'businessintelligence',
    component: BusinessintelligenceComponent
  },
  {
    path: 'industries',
    component: IndustriesComponent
  },
  {
    path: 'Partners',
    component: PartnerComponent
  },
  {
    path: '**',
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top'
})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule {}