import { Injectable } from '@angular/core';
import {Contact} from 'src/classes/contact';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/map';
// import { throwError } from 'rxjs';
// import { catchError } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })

// const httpOptionsPlain={ headers:new HttpHeaders({ 
//   'Content-Type':'application/json',
//  'Accept':'application/json',
//  'Access-Control-Allow-Origin':'*',
//   'Access-Control-Allow-Methods':'PUT,GET,POST,DELETE, OPTIONS,PATCH,DELETE',
//   'ACCESS-Control-Allow-Headers':'Origin,X-Token-Auth,Authorization,X-Requested-With,Content-Type,Accept',
//   'ACCESS-Control-Allow-Credentials':'true'

// }) 
// };
//http://localhost:4200/, http://apps.hardandsoft.ca/php/send.php
@Injectable({
  providedIn: 'root'
})
export class SendemailService {

 

  constructor( public http: HttpClient) { 

  }

  onSendResponseEmail(_body: Contact):Observable<any>{   
    return this.http.post<any>('http://api.hardandsoft.ca/send.php',JSON.stringify(_body)).pipe(
      map(
        (response)=>{
          if (response){
            return response;
          }
        },
        (error:any)=>{
          return error;
        }
      ))
    //  .subscribe
    //  (
    //   (res)=>{
    //     console.log(res);
    //   }
    // );
    
  }

}
