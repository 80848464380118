import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-homecontent',
  templateUrl: './homecontent.component.html',
  styleUrls: ['./homecontent.component.css']
})
export class HomecontentComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) { }
  ngOnInit() {
  }
  GoToRepairComponent() {
    console.log('Repair component');
    this.router.navigate(['/repair'], {queryParams: {page: 1}});
  }

  loadMaintenanceComponent() {
    console.log('Maintenance component');
    this.router.navigate(['/maintenance'], {queryParams: {page: 2}});
  }
  loadNetworkComponent() {
    this.router.navigate(['/networksolutions'], {queryParams: {page: 3}});
  }
  loadExperienceDesignComponent() {
    this.router.navigate(['/userexperience'], {queryParams: {page: 4}});
  }
  loadDevelopmentComponent() {
    this.router.navigate(['/development'], {queryParams: {page: 5}});
  }
  loadBusinessIntelligenceConsultingComponent() {
    this.router.navigate(['/businessintelligence'], {queryParams: {page: 6}});
  }
  
}
