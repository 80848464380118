<div class="jumbotron jumbotron-dark-alt">
    <div class="container">
    <div class="row align-items-center justify-content-center">
    <div class="col-lg-6 col-lg-offset-2">
        <img src="assets/img/getintouch.png"  class="img-fluid" alt=""/>
    </div>
    <div class="col-lg-6">       
            <h2>{{title}}</h2>
        <p>{{body}} </p>
        <div class="border-btn">
                <div class="row text-center">
                 <a class="btn3" routerLink="/contact" [queryParams]="{page:pageindex}">Get in Touch Now! </a>
                </div>
             </div>
    </div>
    </div>
    </div>
    </div>
