<app-header></app-header>  
<div class="jumbotron jumbotron-success d-flex align-items-center min-vh-100">
    <div class="container text-center">
          <h1 class="styleheader display-4"><strong>  We Focus on Quality & Cost-Effective Solutions </strong></h1> 
    </div>
</div>   
        <div class="container-fluid jumbotron-info">       
                <div class="row  h-100 justify-content-center align-items-center ">                 
                      <div class="col-md-12">             
                        <h1 class="card-title">Our Solutions Apps</h1>                       
                    </div>
                      <p class="justify">we don't have limitantion about what application design and develop just this is a list about the idea, but we always
                         we are ready to carry out and accomplish YOUR PROJECT.
                        </p>
                    </div>
                <div class="row">
                    
                    <div class="col-md-4">
                        <div class="card-box">
                            <div class="card-title">
                                <h2>Health Care apps</h2>
                                <p>We design and developer small applications for any device to support doctors and physycians to monitoring patients receiving a lovely care in any community.</p>       
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-box">
                            <div class="card-title">
                                <h2>Booking apps</h2>
                                <p>Simplify Appointment for you Business and Management with, we can customize your task business such automated text/email reminders, accept online payments. for any business kind, we create your application or customize to your needs </p>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-box">
                            <div class="card-title">
                                <h2> Ecommerce apps</h2>
                                <p>Take advantage with high revenue generation, business expansaion, selling your products 24/7 days a week, we can create your own application since begining Focus on the user, working closely with social media, collecting all information and Go with Mobile apps also.  </p>
                            </div>
                           
                        </div>
                    </div>
            
                </div>
                <div class="row">
            
                    <div class="col-md-4">
                        <div class="card-box">
                            <div class="card-title">
                                <h2>Restaurants apps</h2>
                                <p> Are you a restaurant owner? want to integrate or develop the own reservation apps, not doubt we can help what you need, Technology offers a means to speed up processes and improve the customer experience.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-box">
                            <div class="card-title">
                                <h2>Fleet Management apps</h2>
                                <p>Is the management of commercial motor vehicles such cars, vans, truck etc etc etc, we can combines solutions GPS tracking with administrative approach that allows companies to organize and coordinate work vehicles. </p>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div> 
            <app-getintouch [pageindex]=pagenumber ></app-getintouch>
            <app-footer></app-footer>
          