<app-header></app-header>

<div
  class="jumbotron jumbotron-success jumbotron-fluid d-flex align-items-center min-vh-100"
>
  <div class="container text-center">
    <h1 class="styleheader display-4">
      Spill The Beans
      <p>Take us to Your Leader in Your Project</p>
    </h1>
  </div>
</div>
<div class="jumbotron jumbotron-sm">
  <div class="container">
    <div class="row contact-form">
      <div class="col-md-9 col-lg-10 mx-auto" *ngIf="!IsnotSubmit">
        <section class="get-in-touch">
          <h1 class="title">Get in touch</h1>
          <div id="demoFont" class="offset-md-3">
            We would love to hear from you !
          </div>
          <form [formGroup]="registrationForm" (submit)="sendEmail($event)">
            <div class="group-gap offset-md-2">
              <div class="row">
                <div class="form-field col-lg-8">
                  <label class="form-label" for="fullname"
                    >Full Name<span style="color: red"> *</span></label
                  >
                  <input
                    formControlName="fullname"
                    class="input-text js-input form-control"
                    type="text"
                    [(ngModel)]="ContactModel.fullname"
                    [ngModelOptions]="{ standalone: true }"
                    [ngClass]="{
                      error:
                        registrationForm.get('fullname').errors &&
                        registrationForm.get('fullname').touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('fullname').errors?.required &&
                      registrationForm.get('fullname').touched
                    "
                  >
                    <sup>*</sup>Please enter Full Name(First name and Last name)
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('fullname').errors?.pattern &&
                      registrationForm.get('fullname').touched
                    "
                  >
                    <sup>*</sup>Just character
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 form-field">
                  <label class="form-label" for="email"
                    >Email <span style="color: red"> *</span></label
                  >
                  <input
                    class="input-text js-input form-control"
                    type="text"
                    formControlName="email"
                    [(ngModel)]="ContactModel.email"
                    [ngModelOptions]="{ standalone: true }"
                    [ngClass]="{
                      error:
                        registrationForm.get('email').errors &&
                        registrationForm.get('email').touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('email').errors?.required &&
                      registrationForm.get('email').touched
                    "
                  >
                    <sup>*</sup>Please enter your email
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('email').errors?.pattern &&
                      registrationForm.get('email').touched
                    "
                  >
                    <sup>*</sup>Please enter valid email
                  </div>
                </div>
                <div class="form-field col-lg-3">
                  <label class="form-label" for="phone"
                    >Phone <span style="color: red"> *</span></label
                  >
                  <input
                    class="input-text js-input form-control"
                    type="text"
                    [(ngModel)]="ContactModel.phone"
                    [ngModelOptions]="{ standalone: true }"
                    formControlName="phone"
                    [ngClass]="{
                      error:
                        registrationForm.get('phone').errors &&
                        registrationForm.get('phone').touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('phone').errors?.required &&
                      registrationForm.get('phone').touched
                    "
                  >
                    <sup>*</sup>Please enter your phone
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('phone').errors?.pattern &&
                      registrationForm.get('phone').touched
                    "
                  >
                    <sup>*</sup>Please enter valid phone.
                  </div>
                </div>
              </div>

              <!-- <div class="row"> -->
              <!-- <div class="form-field col-lg-6 ">
						<label class="form-label" for="company">Company Name</label>
						<input id="company" class="input-text js-input form-control" type="text" >
						 [(ngModel)]="ContactModel.companyname" name="companyname" >  				  
					</div>  -->

              <!-- </div> -->
              <div class="row">
                <div class="form-field col-lg-8">
                  <label class="form-label" for="bwebsite">Website/Blog</label>
                  <input
                    id="bwebsite"
                    class="input-text js-input form-control"
                    type="text"
                    [(ngModel)]="ContactModel.url"
                    [ngModelOptions]="{ standalone: true }"
                    name="url"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-field col-md-5 col-lg-5">
                  <label class="form-label" for="datepicker"
                    >Date<span style="color: red"> *</span></label
                  >
                  <!-- <div class="labelWrapper"><label class="form-label d-flex align-items-center my-1" for="datepicker">Date<span class="ml-auto">
						<i class="fas fa-question-circle" onClick="showHelp()"></i></span></label> </div>    -->
                  <input
                    class="form-control input-text js-input"
                    id="datepicker"
                    type="text"
                    placeholder=""
                    name="datepick"
                  />
                  <!-- [(ngModel)]="ContactModel.datepick" >       -->
                  <div class="input-group"></div>
                </div>
                <div class="form-field col-md-5 col-lg-4">
                  <label class="form-label" for="timepicker"
                    >Time<span style="color: red"> *</span></label
                  >
                  <input
                    class="form-control input-text js-input"
                    id="timepicker"
                    type="time"
                    placeholder=""
                    name="timepick"
                  />
                  <!-- [(ngModel)]="ContactModel.timepick" > -->
                  <div class="input-group"></div>
                </div>
              </div>
              <div class="row">
                <div
                  class="form-field col-md-5 col-lg-5"
                  *ngIf="isShowdeviceType"
                  id="deviecType"
                >
                  <label class="form-label" for="s1"
                    >Device type<span style="color: red"> *</span></label
                  >
                  <select
                    class="show-tick form-control input-text js-input selectpicker"
                    id="s1"
                  >
                    <option>---Select device type---</option>
                    <option
                      *ngFor="let device of DeviceTypes"
                      [value]="device.id"
                    >
                      {{ device.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                class="row"
                *ngIf="isShowCompMaintenanceInfo"
                id="CompMaintenanceInfo"
              >
                <div class="form-field col-md-1 col-lg-2">
                  <label class="form-label" for="totaldevice">Devices</label>
                  <input
                    id="totaldevice"
                    class="input-text js-input"
                    type="text"
                    [ngClass]="{
                      error:
                        registrationForm.get('totaldevice').errors &&
                        registrationForm.get('totaldevice').touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('totaldevice').errors?.required &&
                      registrationForm.get('totaldevice').touched
                    "
                  >
                    <sup>*</sup>Please enter total device
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('totaldevice').errors?.pattern &&
                      registrationForm.get('totaldevice').touched
                    "
                  >
                    <sup>*</sup>Please enter valid total device
                  </div>
                </div>
                <div class="form-field col-md-5 col-lg-6">
                  <label class="form-label" for="s1"
                    >Frequency<span style="color: red"> *</span></label
                  >
                  <select
                    class="selectpicker form-control show-tick input-text selectclass js-input"
                    id="s1"
                    (change)="selectFrequencyChange($event)"
                  >
                    <option>---Frequency services---</option>
                    <option
                      *ngFor="let frequencies of FrequenciesServices"
                      [value]="frequencies.id"
                    >
                      {{ frequencies.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                class="form-field col-md-6 col-lg-6"
                *ngIf="isShowCompNetworkInfo"
                id="CompNetworkInfo"
              >
                <label class="form-label" for="s1"
                  >Network services<span style="color: red"> *</span></label
                >
                <select
                  class="selectpicker form-control show-tick input-text js-input"
                  id="s1"
                  (change)="selectNetworkChange($event)"
                >
                  <option
                    *ngFor="let networkservice of NetworkServices"
                    [value]="networkservice.id"
                  >
                    {{ networkservice.name }}
                  </option>
                </select>
                <!-- <div class="invalid-feedback"   *ngIf="registrationForm.get('netService').errors?.required && registrationForm.get('netService').touched">
					<sup>*</sup>Please select network service				   					
				 </div>	 -->
              </div>
              <div class="row">
                <div
                  class="form-field col-md-4 col-lg-5"
                  *ngIf="isShowCompDevlopmentInfo"
                  id="CompDevlopmentInfo"
                  (change)="selectApplicationChange($event)"
                >
                  <label class="form-label" for="s1"
                    >Application type<span style="color: red"> *</span></label
                  >
                  <select
                    class="selectpicker form-control show-tick input-text selectclass js-input"
                    id="s1"
                  >
                    <option>---Select Application---</option>
                    <option
                      *ngFor="let Applicationservice of ApplicationsServices"
                      [value]="Applicationservice.id"
                    >
                      {{ Applicationservice.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-field col-md-4 col-lg-5"
                  *ngIf="isShowCompDevlopmentInfo"
                  id="CompDevlopmentInfo"
                  (change)="selectSolutionsChange($event)"
                >
                  <label class="form-label" for="s1"
                    >Solutions Type<span style="color: red"> *</span></label
                  >
                  <select
                    class="selectpicker form-control show-tick input-text selectclass js-input"
                    id="s1"
                  >
                    <option>---Select solutions---</option>
                    <option
                      *ngFor="let AppTypeService of ApplicationsTypeServices"
                      [value]="AppTypeService.id"
                      [name]="AppTypeService.name"
                    >
                      {{ AppTypeService.name }}
                    </option>
                  </select>
                </div>

                <div
                  class="col-md-10 col-lg-12 form-field"
                  *ngIf="isShowCompDevlopmentInfo"
                  id="CompDevlopmentInfo"
                >
                  <h5 class="form-label" for="switch">
                    Does you need User Experience services?
                  </h5>
                  <div
                    class="radio-item custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="defaultInline1"
                      name="inlineDefaultRadiosExample"
                    />
                    <label class="custom-control-label" for="defaultInline1"
                      >Yes</label
                    >
                  </div>

                  <div
                    class="radio-item custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="defaultInline2"
                      name="inlineDefaultRadiosExample"
                    />
                    <label class="custom-control-label" for="defaultInline2"
                      >No</label
                    >
                  </div>

                  <div
                    class="radio-item custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="defaultInline3"
                      name="inlineDefaultRadiosExample"
                    />
                    <label class="custom-control-label" for="defaultInline3"
                      >I don't know
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-4 col-lg-5 form-field"
                  *ngIf="isShowUXserviceInfo"
                  id="UXserviceInfo"
                >
                  <label class="form-label" for="s1"
                    >UX services<span style="color: red"> *</span></label
                  >
                  <select
                    class="selectpicker form-control show-tick input-text selectclass js-input"
                    id="s1"
                    (change)="selectUXChange($event)"
                  >
                    <option>---Select UX services---</option>
                    <option *ngFor="let ux of UXTypeServices" [value]="ux.id">
                      {{ ux.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-field col-md-4 col-lg-5"
                  *ngIf="isShowUXserviceInfo"
                  id="UXserviceInfo"
                >
                  <label class="form-label" for="s1"
                    >Solutions<span style="color: red"> *</span></label
                  >
                  <select
                    class="selectpicker form-control show-tick input-text selectclass js-input"
                    id="s1"
                    (change)="selectTypeAppChange($event)"
                  >
                    <option>---Select Type Application---</option>
                    <option
                      *ngFor="
                        let Applicationservice of ApplicationsTypeServices
                      "
                      [value]="Applicationservice.id"
                    >
                      {{ Applicationservice.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="form-field col-md-4 col-lg-5"
                *ngIf="isShowSolutionsserviceInfo"
                id="SolutionsInfo"
              >
                <label class="form-label" for="s1"
                  >Select solutions<span style="color: red"> *</span></label
                >
                <select
                  class="selectpicker form-control show-tick input-text selectclass js-input"
                  id="s1"
                  (
                >
                  <option>Select Type Application</option>
                  <option
                    *ngFor="let Applicationservice of ApplicationsServices"
                    [value]="Applicationservice.id"
                  >
                    {{ Applicationservice.name }}
                  </option>
                </select>
              </div>
              <div
                class="form-field col-md-4 col-lg-5"
                *ngIf="isShowAffiliateserviceInfo"
                id="AffiliateserviceInfo"
              >
                <label class="form-label" for="s1"
                  >What service you want
                </label>
                <select
                  class="selectpicker form-control show-tick input-text selectclass js-input"
                  id="s1"
                >
                  <option>---Select Service---</option>
                  <option *ngFor="let srv of Services" [value]="srv.id">
                    {{ srv.name }}
                  </option>
                </select>
              </div>
              <!-- <div class="form-field col-md-4 col-lg-5" *ngIf="isShowAffiliateserviceInfo"  id="AffiliateserviceInfo" >
					<label class="label" for="s1">City in Canada </label>
				<select class="selectpicker form-control show-tick input-text selectclass js-input" id="s1">
					<option>Select City </option>
					<option>Ottawa</option>
					<option>vancouber</option>
					<option>Toronto</option>
					<option>Montreal</option>
					<option>Quebec City</option>
					<option>Edmonton</option>
					<option>Alberta</option>
				</select>
				</div> 
				<div class="form-field col-md-2 col-lg-3" *ngIf="isShowAffiliateserviceInfo"  id="AffiliateserviceInfo" >
					<input id="postalcode" class="input-text js-input" type="text" [(ngModel)]="ContactModel.postalcode name="postalcode">
					<label class="label" for="postalcode">Postal Code</label>
				</div>-->
              <div class="row">
                <div class="form-field col-lg-10">
                  <label class="form-label" for="subject"
                    >Subject<span style="color: red"> *</span></label
                  >
                  <input
                    id="subject"
                    class="input-text js-input form-control"
                    type="text"
                    formControlName="subject"
                    [(ngModel)]="ContactModel.subject"
                    [ngModelOptions]="{ standalone: true }"
                    [ngClass]="{
                      error:
                        registrationForm.get('subject').errors &&
                        registrationForm.get('subject').touched
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      registrationForm.get('subject').errors?.required &&
                      registrationForm.get('subject').touched
                    "
                  >
                    <sup>*</sup>Please enter a subject
                  </div>
                </div>
                <div class="form-field col-lg-10">
                  <label class="label" for="yourcomments"
                    >Message<span style="color: red"> *</span></label
                  >
                  <div class="input-group">
                    <textarea
                      class="form-control textcls inputclass"
                      style="height: 170px; overflow-y: scroll"
                      placeholder="Comments"
                      rows="35"
                      cols="50"
                      id="message"
                      [(ngModel)]="ContactModel.message"
                      [ngModelOptions]="{ standalone: true }"
                      name="message"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="form-field col-lg-12">
                <button
                  type="submit"
                  class="btn btn-space btn-outline-primary btn-round"
                >
                  {{ caption_submit }}
                </button>
              </div>
            </div>
          </form>
          <!-- Form ends  [disabled]="registrationForm.invalid"-->
        </section>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
