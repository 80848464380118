<div id="test"></div>
<section class="details-card">
    <div class="container">
      <div class="row ">
        <div class=" col-lg-12 site-heading h-100 justify-content-center align-items-center">
         <h3>What we Do?</h3>
      </div>
      </div>
        <div class="row">
          <div class="col-md-4 mt-4">
          <div class="card profile-card-4">
                  <div class="card-img-block">
                      <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                      <img class="img-fluid" src="assets/img/clinicapc.jpg" alt="Card image cap">
                  </div>
                  <div class="card-body pt-5">
                      <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
                      <h5 class="card-title text-center">Computer Repair</h5>
                      <p class="card-text text-center">Friendly and Full services professional in Home or Office, we fix all brand and any problems in your Computer/laptop
                        .</p>
                        
                    
                  </div>

                      <button id="Repair" (click)="GoToRepairComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
              </div>


      </div>

            <div class="col-md-4 mt-4">
            <div class="card profile-card-4">
                    <div class="card-img-block">
                        <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        <img class="img-fluid" src="assets/img/pc_maintenance.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body pt-5">
                        <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
                        <h5 class="card-title text-center">Computer Maintenance</h5>
                        <p class="card-text text-center">We keep yor computers and all devices in good state without debris and dust running properly like brand new.</p>
                        
                    </div>
                      <button id="id1" (click)="loadMaintenanceComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
                </div>

        </div>
        <div class="col-md-4 mt-4">
        <div class="card profile-card-4">
                <div class="card-img-block">
                    <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                    <img class="img-fluid" src="assets/img/network_solutions.jpg" alt="Card image cap">
                </div>
                <div class="card-body pt-5">
                    <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
                    <h5 class="card-title text-center">Network Solutions</h5>
                    <p class="card-text text-center">We design your best Network Solution based on your need it, provide you with detailed drawings and techniocal documentation.</p>
                    
                </div>
                  <button (click)="loadNetworkComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
            </div>
       </div>

        </div>
        <hr/>
        <div class="row">
          <div class="col-md-4 mt-4">
          <div class="card profile-card-4">
                  <div class="card-img-block">
                      <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                      <img class="img-fluid" src="assets/img/websoftdevelopment.jpg" alt="Card image cap">
                  </div>
                  <div class="card-body pt-5">
                      <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
                      <h5 class="card-title text-center">Application Development</h5>
                      <p class="card-text text-center">We customize software and web development services to an full spectrum accross technologies for a set of industries and technology solutions.</p>
                      
                  </div>
                    <button  (click)="loadDevelopmentComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
              </div>

      </div>
      <div class="col-md-4 mt-4">
      <div class="card profile-card-4">
              <div class="card-img-block">
                  <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                  <img class="img-fluid" src="assets/img/clinicapc.jpg" alt="Card image cap">
              </div>
              <div class="card-body pt-5">
                  <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
                  <h5 class="card-title text-center">IT Consulting</h5>
                  <p class="card-text text-center">We going to advising your organization how the best way to use information technology  straight our business objectives or overcomes problems.</p>
                  
              </div>
                <button (click)="loadConsultingComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
          </div>

  </div>
  <div class="col-md-4 mt-4">
  <div class="card profile-card-4">
          <div class="card-img-block">
              <div class="info-box">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
              <img class="img-fluid" src="assets/img/user_experience.jpg" alt="Card image cap">
          </div>
          <div class="card-body pt-5">
              <img src="assets/img/icon_pcrepair.png" alt="profile-image" class="profile"/>
              <h5 class="card-title text-center">User Experience Design</h5>
              <p class="card-text text-center">We are create a product enhancing user satistaction improving the Usability,Accessibility provided in the interaction with the product.</p>
              
          </div>
            <button (click)="loadExperienceDesignComponent()" class="btn btn-outlined btn-theme btn-lg" data-wow-delay="0.7s">Read more..</button>
      </div>
 </div>
        </div>
    </div>
</section>
<br>
<hr class="style8">
<br>
<div class="jumbotron jumbotron-sm">
<div class="container">
  <div class="row">
    <div class="col-lg-4">

     <i class="fa fa-users" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>Experience</h4>
      <p>Donec sed odio dui. Etiam porta sem malesuada magna mollis euismod. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna.</p>

    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
      <i class="fa fa-puzzle-piece" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>Comprensive Services</h4>
      <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>

    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
       <i class="fa fa-thumbs-up" style="color: rgb(72, 168, 237); font-size: 96px; box-sizing: content-box; line-height: 143px; text-align: center; width: 143px; height: 143px; display: inline-block; overflow: hidden; border-radius: 50%; background-color: rgb(224, 231, 237);"></i>
      <h4>Customer Satisfaction</h4>
      <p>Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>

    </div><!-- /.col-lg-4 -->
  </div><!-- /.row -->
</div>
</div>
