<nav class="navbar navbar-expand-lg navbar-dark navbar-full fixed-top navbar-custom">
  <div class="d-flex flex-grow-1">        
    <a  class="navbar-brand" routerLink=""><img src="assets/img/logoHS.png"></a>
    <!--<div class="w-100 text-right">-->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar"  aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
              <i class="fas fa-bars" style="color:black;font-size:28px;"></i>
            </span>
        </button>
    <!--</div>-->
</div>
  
  <div class="collapse navbar-collapse flex-column  text-right" id="myNavbar">
      <!--<ul class="navbar-nav w-100 justify-content-end px-3">
          
          <li class="nav-item1">
            <a class="nav-link" routerLink="/services">Blog</a>
          </li>
          <li class="nav-item1">
            <a class="nav-link" routerLink="/services">Testimonial</a>
          </li>
      </ul>-->
      <ul class="navbar-nav ml-auto flex-nowrap">
        <!--<li class="nav-item"  >
          <a  class="nav-link m-2 menu-item" routerLink="">Home</a>
      </li>-->
      <li class="nav-item" >
          <a class="nav-link m-2 menu-item" routerLink="/services">Services</a>
      </li>
      <li class="nav-item" >
        <a class="nav-link m-2 menu-item" routerLink="/industries" [queryParams]="{page:7}">Industries</a>
    </li>
    <li class="nav-item" >
      <a class="nav-link m-2 menu-item" routerLink="/Partners" [queryParams]="{page:8}">Referral Program</a>
  </li> 
      <li class="nav-item" *ngIf="isLoadedDevelopmentSolution">
          <a  class="nav-link m-2 menu-item" routerLink="/oursolutions" [queryParams]="{page:9}">Our Solution</a>
      </li>        
      <li class="nav-item" >
          <a class="nav-link m-2 menu-item" routerLink="/contact">Get in touch</a>
      </li>
      </ul>
  </div>
</nav>

